import { faCalendar, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import styles from '../../../modules.module.scss'
import EmptyState from '../../../common/EmptyState'
import moment from 'moment'

type SortOrder = 'asc' | 'desc';

export default function IdentityDoc({ documents, userProfile, email }: any) {

  useEffect(() => {
    document.title = "User Profile - Documents"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [])

  const [sortBy, setSortBy] = useState<string>('upload_date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const sortedDocuments = [...(documents || [])].sort((a: any, b: any) => {
    if (sortBy === 'file_name') {
      return sortOrder === 'asc'
        ? a.file_name.localeCompare(b.file_name)
        : b.file_name.localeCompare(a.file_name);
    }
    if (sortBy === 'upload_date') {
      return sortOrder === 'asc'
        ? new Date(a.upload_date).getTime() - new Date(b.upload_date).getTime()
        : new Date(b.upload_date).getTime() - new Date(a.upload_date).getTime();
    }
    return 0;
  });


  return (
    <>
      {documents && documents.length !== 0 ? (
        <table className={styles.table} style={{ width: '100%' }}>
          <thead>
            <tr className={styles.tableHead}>
              <th style={{ paddingLeft: 8, textAlign: 'start' }} onClick={() => handleSort('file_name')}>
                <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                  <span style={{ marginRight: '4px' }}>File Name</span>
                  <FontAwesomeIcon
                    icon={sortBy === 'file_name' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                    size="1x"
                    height={12}
                    width={11}
                  />
                </div>
              </th>

              <th style={{ paddingLeft: 8 }} onClick={() => handleSort('upload_date')}>
                <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                  <span style={{ marginRight: '4px' }}>Last Updated</span>
                  <FontAwesomeIcon
                    icon={sortBy === 'upload_date' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                    size="1x"
                    height={12}
                    width={11}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedDocuments.map((item: any) => (
              <tr key={item.fileid} className={styles.tableActivityRowRansomWatchList}>
                <td className={styles.tableCell} style={{ width: '88%' }}>
                  <p style={{ color: '#cbcbcb', fontSize: 14, textAlign: 'start' }}>{item?.file_name}</p>
                </td>
                <td style={{ paddingLeft: 8 }} className={styles.tableCell}>
                  <div style={{ display: 'flex', alignItems: "center", gap: 10, justifyContent: "start" }}>
                    <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="grey" />{' '}
                    {item?.upload_date && moment(item?.upload_date).format('MMM DD, YYYY')}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
    </>
  );


}
