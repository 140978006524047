import React, { useEffect } from 'react'
import { faCalendar, faCamera, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from '../../modules.module.scss'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4';

export default function WeeklyReports() {
    const navigate = useNavigate()
    useEffect(() => {
      document.title = "Weekly Reports"
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    }, [])
    
  return (
    <div style={{marginTop:20}}>
        <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between", height:"45px"}}>
                            <div className={styles.searchAndIcon}>
                                <input className={styles.search} name='search' placeholder='Search'/>
                                <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                            </div>
                        </div>

                        <table className={styles.table}>
                     
                      <tbody>
                          <tr onClick={()=>navigate("/reports/reportDetail")} className={styles.tableActivityRowRansomWatchList}>
                            <td style={{width: '904px', padding: '14px 16px'}} className={styles.tableCell}>
                            April weekly threat report - Week 4
                            </td>
                              
                              
                              <td style={{width: '190px', padding: '14px 16px'}} className={styles.tableCell} >
                                <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/>
                                {" "}April 28, 2024
                              </td>
                            </tr>
                          
                        
                        </tbody>
                    </table>
    </div>
  )
}
