import React, { useEffect, useState } from 'react'
import styles from "../../../modules.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChevronDown, faListDots } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Header from '../../../common/Header'
import Menu from '../../../common/Menu'
import { useSelector } from 'react-redux'
import { brandIntelSelector } from '../../../../../core/selectors/brandIntelSelector'
import moment from 'moment'
import ReactGA from 'react-ga4';
import { Dark } from '../../../../../core/constants'

function DarkwebDetail() {
    const navigate = useNavigate()
    // const { darkDetail }= useSelector(brandIntelSelector)
    const [darkDetail, setdarkDetail] = useState<Dark| null>(null)
    useEffect(() => {
        document.title = "Brand Intel - Dark Web Detail"
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
       setdarkDetail(JSON.parse(localStorage.getItem('leakDetail') || "") as Dark)
    }, [])

  return (
    <div className={styles.main}>
        <Header/>
        <Menu/>
    <div className={styles.content}>
        <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>navigate("/brandIntel")} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <div>
                        <span>Go Back</span>
                        </div>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10, marginTop: 4}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span>{darkDetail?.author?.split('"')?.[1]?.replace('(',"")?.replace(")","")}</span>
                        {/* <span className={styles.scoreSeverity}>9.1</span> */}
                    </div>  
                </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', gap: 5, fontSize: 14}}>
                    <p style={{color: "#afafaf"}}>Last updated</p>
                    <p style={{color: "white"}}>{moment(darkDetail?.detection_date)?.format("MMMM DD, YYYY")}</p>
                    
                </div>


                <table className={styles.table}style={{marginTop:30}}>
    <tbody>
    <tr  className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px', padding: '0px 16px'}} className={styles.tableCell}>
          Post Title
          </td>
              <td style={{width: '918px',padding: '0px 16px'}} className={styles.tableCell}>
                 <p>{darkDetail?.content?.split('"')?.[1]}</p>
              </td>
        </tr>
        
       
       
        
        
      </tbody>
  </table>
              
    </div>
    </div>
  )
}

export default DarkwebDetail