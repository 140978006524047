import { createBrowserRouter } from 'react-router-dom'
import { authRouter } from './auth.router'
import { accountRouter } from './account.router'
import Home from '../views/modules/home/homeMain'
import React from 'react'
import Profile from '../views/settings/profile'
import Security from '../views/settings/security'
import Workspace from '../views/settings/workspace'
import Members from '../views/settings/members'
import Watchlists from '../views/settings/watchlists'
import Communication from '../views/settings/communication'
import Support from '../views/settings/support'
import Notification from '../views/modules/common/notificationTabs'
import ReportDetail from '../views/modules/reports/reportDetail'
import ReportsView from '../views/modules/reports/reportTabs'
import Compliance from '../views/modules/compliance/compMain'
import CreditCardDetail from '../views/modules/dataleaks/dataleakTabs/paymentCards/CardTabs'
import ArchiveDetail from '../views/modules/manage/manageTabs/manageDetails/ArchiveDetail'
import ThreatManagementView from '../views/modules/manage/manageMain'
import UserProfileCredential from '../views/modules/dataleaks/dataleakTabs/profile/profileTabs'
import CredentialLeakDetail from '../views/modules/dataleaks/dataleakTabs/credentials/credentialTabs'
import ThreatIntelView from '../views/modules/dataleaks/dataMain'
import Conversations from '../views/modules/ransomware/chats/conversations'
import GroupDetail from '../views/modules/ransomware/groups/groupTabs'
import CountryDetail from '../views/modules/ransomware/countries/countryDetail'
import RansomeView from '../views/modules/ransomware/ransTabs'
import DomainTakeDownRequest from '../views/modules/brandMonitor/brandTabs/domains/domainTakedown'
import SocialMedia from '../views/modules/brandMonitor/brandTabs/brandSocial'
import DarkwebDetail from '../views/modules/brandMonitor/brandTabs/darkweb/darkDetail'
import DomainDetail from '../views/modules/brandMonitor/brandTabs/domains/domainTabs'
import BrandIntelView from '../views/modules/brandMonitor/brandMain'
import VulnerabilityDetail from '../views/modules/vulnerability/vulTabs/vulListInfo/vulDetail'
import VulnerabilitiesView from '../views/modules/vulnerability/vulMain'
import Onboarding from '../views/auth/onboarding'
import Verification from '../views/auth/verification'
import Forgotpassword from '../views/auth/forgotpass'
import Newpassword from '../views/auth/newpass'
import Login from '../views/auth/login'
import { TokenVerifier } from '../TokenVerifier'
import serverLeaks from '../views/modules/dataleaks/dataleakTabs/dataServers'
import ErrorPage from './ErrorPage'
import PlaceDetail from '../views/modules/brandMonitor/brandTabs/places/placeDetail'
import VictimsDetail from '../views/modules/ransomware/victims/victimDetails'
import SetPassword from '../views/auth/SetPassword'
const router = createBrowserRouter(
    [

        // new users are redirected to set their passwords
        {
            path: "/newPassword",
            element: <Newpassword />,
        },
        {
            path: "/set-password",
            element: <SetPassword />,
        },

        // login path
        {
            path: "/login",
            element: <Login />
        },

        // Forgotpassword path
        {
            path: "/forgotPassword",
            element: <Forgotpassword />
        },

        // otp verification
        {
            path: "/verification",
            element: <Verification />
        },

        {
            path: "/onboarding",
            element: <Onboarding />
        },
        {
            path: "/vulnerabilities",
            element: <TokenVerifier><VulnerabilitiesView /></TokenVerifier>
        },
        {
            path: "/vulnerabilities/vulnerabilityDetail",
            element: <TokenVerifier><VulnerabilityDetail /></TokenVerifier>
        },
        {
            path: "/brandIntel",
            element: <TokenVerifier><BrandIntelView /></TokenVerifier>
        },
        {
            path: "/brandIntel/placeDetail",
            element: <TokenVerifier><PlaceDetail /></TokenVerifier>
        },
        {
            path: "/brandIntel/domainDetail",
            element: <TokenVerifier><DomainDetail /></TokenVerifier>
        },
        {
            path: "/brandIntel/darkwebDetail",
            element: <TokenVerifier><DarkwebDetail /></TokenVerifier>
        },
        {
            path: "/brandIntel/socialProfile",
            element: <TokenVerifier><SocialMedia /></TokenVerifier>
        },
        {
            path: "/brandIntel/requestTakeDown",
            element: <TokenVerifier><DomainTakeDownRequest /></TokenVerifier>
        },
        {
            path: '/ransomwareIntel',
            element: <TokenVerifier><RansomeView /></TokenVerifier>,
            errorElement: <ErrorPage />

        },
        {
            path: '/ransomwareIntel/countryDetail',
            element: <TokenVerifier><CountryDetail /></TokenVerifier>,
            errorElement: <ErrorPage />
        },
        {
            path: '/ransomwareIntel/groupDetail',
            element: <TokenVerifier><GroupDetail /></TokenVerifier>,
            errorElement: <ErrorPage />
        }
        ,
        {
            path: '/ransomwareIntel/conversations',
            element: <TokenVerifier><Conversations /></TokenVerifier>,
            errorElement: <ErrorPage />
        },
        {
            path: '/threatIntel',
            element: <TokenVerifier><ThreatIntelView /></TokenVerifier>
        },
        {
            path: '/threatIntel/credentialLeaksDetail',
            element: <TokenVerifier><CredentialLeakDetail /></TokenVerifier>
        },
        {
            path: '/threatIntel/userProfile',

            element: <TokenVerifier><UserProfileCredential /></TokenVerifier>
        },
        {
            path: '/threatIntel/creditCardDetail',
            element: <TokenVerifier><CreditCardDetail /></TokenVerifier>
        },
        {
            path: "/threatManagement",
            element: <TokenVerifier><ThreatManagementView /></TokenVerifier>
        },
        {
            path: "/threatManagement/archiveDetail",
            element: <TokenVerifier><ArchiveDetail /></TokenVerifier>
        },
        {
            path: "/threatManagement/threatDetail",
            element: <TokenVerifier><CreditCardDetail /></TokenVerifier>
        },
        {
            path: "/compliance",
            element: <TokenVerifier><Compliance /></TokenVerifier>
        },
        {
            path: '/reports',
            element: <TokenVerifier><ReportsView /></TokenVerifier>
        },
        {
            path: '/reports/reportDetail',
            element: <TokenVerifier><ReportDetail /></TokenVerifier>
        }
        ,
        {
            path: '/notifications',
            element: <TokenVerifier><Notification /></TokenVerifier>
        },
        {
            path: '/profile',
            element: <TokenVerifier><Profile /></TokenVerifier>
        },
        {
            path: '/security',
            element: <TokenVerifier><Security /></TokenVerifier>
        },
        {
            path: '/workspace',
            element: <TokenVerifier><Workspace /></TokenVerifier>
        },
        {
            path: '/members',
            element: <TokenVerifier><Members /></TokenVerifier>
        },
        {
            path: '/watchlists',
            element: <TokenVerifier><Watchlists /></TokenVerifier>
        },
        {
            path: '/communication',
            element: <TokenVerifier><Communication /></TokenVerifier>
        },
        {
            path: '/ransomwareIntel/VictimsDetail',
            element: <TokenVerifier><VictimsDetail /></TokenVerifier>
        },
        {
            path: "/support",
            element: <TokenVerifier><Support /></TokenVerifier>
        },
        {
            path: "/",
            element: <TokenVerifier><Home /></TokenVerifier>,
            errorElement: <TokenVerifier><Home /></TokenVerifier>
        },
        {
            path: "/error",
            element: <ErrorPage />,

        },

    ],
);

export default router;
