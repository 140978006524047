import React, { useEffect, useState } from 'react'
import styles from  "../../modules.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCheckCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../../core/selectors/threatIntelSelector'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import iconMenu5 from "../../../../icons/main/menu/menu-5.svg"
import iconMenu6 from "../../../../icons/main/menu/menu-6.svg"
import iconMenu4 from "../../../../icons/main/menu/menu-4.svg"
import circlCheck from "../../../../icons/main/icons/circle-check.svg"
import { setCredentialLeakDetail } from '../../../../core/actions/threatIntelActions'
import { CredentialsLeak, NotificationBell } from '../../../../core/constants'
import { getNotificationsApi, updateNotificationsApi } from '../../../../core/api/notificationApi'
import { notificationSelector } from '../../../../core/selectors/notificationBellSelector'
import moment from 'moment'
import { supabaseClient } from '../../../../core/supabase'
import { setNamePageLocalStorage } from '../../../../core/actions/stepsNavPageActions'
import EmptyStateNot from '../EmptyStateNot'


function formatTimestamp(timestamp: string) {
    const now = moment();
    const inputTime = moment(timestamp);
    const duration = moment.duration(now.diff(inputTime));
  
    const seconds = duration.asSeconds();
    const minutes = duration.asMinutes();
    const hours = duration.asHours();
    const days = duration.asDays();
  
    if (seconds < 60) {
      return `${Math.floor(seconds)} seconds ago`;
    } else if (minutes < 60) {
      return `${Math.floor(minutes)} minutes ago`;
    } else if (hours < 24) {
      return `${Math.floor(hours)} hours ago`;
    } else {
      return inputTime.format('MMM DD, YYYY');
    }
  }
export default function Unread() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {credentialsLeak} = useSelector(threatIntelSelector)
    const [messages, setMessages] = useState<any[]>([]);
    const { notifs, notifsUser } = useSelector(notificationSelector)
    
    const fetchNotifications = async () => {
        // Fetch or refresh notifications here
        const { data, error } = await supabaseClient
            .from('NotificationUser')
            .select('*')
            .eq('read', false);
        
        if (!error) {
            setMessages(data);
        }
    };
   
    useEffect(() => {
        fetchNotifications();
    }, []);

    const NotificationUnread = notifs?.filter(item => notifsUser.some((not:any) => not.read === false && item.id === not.notifId))

    const markAsRead = async(id:any, event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    
        // Optimistically update the UI
        setMessages(prevMessages =>
            prevMessages.map(msg =>
                msg.id === id ? { ...msg, read: true } : msg
            )
        );

        
    
        try {
            // Update on Supabase in the background
            const { error } = await supabaseClient
                .from('NotificationUser')
                .update({ read: true })
                .eq('notifId', id);
        } catch (error) {console.error("Failed to mark notification as read:");}
    }

    const renderCredential = (msg:any, id:any) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList} style={{cursor:"pointer"}} onClick={() => {
            setNamePageLocalStorage("credentialLeaks")
            navigate("/threatIntel")
        }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img src={iconMenu6} width={18}/>
                </div>                        
            </td>
            <td className={styles.tableCell} style={{padding: 16}}>
                <span style={{ fontSize: '14', color: 'white'}}>Login credentials for {msg?.username} linked with one of your domains has appeared in a recent data leak.</span>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5 }}>
                    <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                    <span style={{color: 'grey'}}>{ formatTimestamp(msg.created_at)}</span>
                </div>
            </td>
            <td  className={styles.tableCell} style={{width:160}}>
                <div onClick={(event) => {markAsRead(id, event)}} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                    <img src={circlCheck}/>
                    <p style={{fontWeight:400}}>Mark as read</p>
                </div>
            </td>
        </tr>
        </tbody>
    }
    const creditsCard = NotificationUnread.filter(item => item.isCreditsCard === true)
    const renderCreditsCard = (msg:any, id:any) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList } style={{cursor:"pointer"}} onClick={() => {
            setNamePageLocalStorage("paymentCardLeaks")
            navigate("/threatIntel")
        }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img src={iconMenu6} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell} style={{padding: 16}}>
                <span style={{ fontSize: '14', color: 'white'}}>Payment Card {msg?.cardnumber 
  ? `${msg.cardnumber.slice(0, 4)} ${msg.cardnumber.slice(4, 6)}** **** ${msg.cardnumber.slice(-4)}`
  : ""} has appeared in a recent data leak.</span>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                    <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                    <span style={{color: 'grey'}}>{msg?.created_at && formatTimestamp(msg.created_at)}</span>
                </div>
            </td>
            <td  className={styles.tableCell} style={{width:160}}>
                <div onClick={(event) => {markAsRead(id, event)}} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                    <img src={circlCheck}/>
                    <p style={{fontWeight:400}}>Mark as read</p>
                 </div>
            </td>
        </tr>
            </tbody>
    }
    const renderVul = (msg: any, id:any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList} style={{cursor:'pointer'}} onClick={() => {
            setNamePageLocalStorage("list")
            navigate("/vulnerabilities")
            }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img src={iconMenu5} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell} style={{padding: 16}}>
                <span style={{ fontSize: '14', color: 'white'}}>{msg?.cve_id} - {msg?.description?.substring(0,120)} ...</span>
                <div style={{display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                    <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                    <span style={{color: 'grey'}}>{created_at && formatTimestamp(created_at)}</span>
                </div>
            </td>
            <td  className={styles.tableCell} style={{width:160}}>
                <div onClick={(event)=>{markAsRead(id, event)}} style={{display:'flex', justifyContent:'end',paddingRight:20,alignItems:'center', gap:10, cursor:'pointer'}}>
                    <img src={circlCheck}/>
                    <p style={{fontWeight:400}}>Mark as read</p>
                </div>
            </td>
        </tr> 
            </tbody>
    }
    const renderRansom = (msg: any, id:any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList} style={{cursor:'pointer'}} onClick={() => {
            setNamePageLocalStorage("countries")
            navigate("/ransomwareIntel")
        }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img src={iconMenu6} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell} style={{padding: 16}}>
                <span style={{ fontSize: '14', color: 'white'}}>New ransomware attack by {msg.name} detected.</span>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                    <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                    <span style={{color: 'grey'}}>{created_at && formatTimestamp(created_at)}</span>
                </div>
            </td>
            <td  className={styles.tableCell} style={{width:160}}>
                <div onClick={(event)=>{markAsRead(id, event)}} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                    <img src={circlCheck}/>
                    <p style={{fontWeight:400}}>Mark as read</p>
                </div>
            </td>
        </tr>   
            </tbody>
    }
    const renderDarkweb = (msg: any,id:any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList } style={{cursor:"pointer"}} onClick={() => {
            setNamePageLocalStorage("groups")
            navigate("/ransomwareIntel")
        }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img src={iconMenu4} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell} style={{padding: 16}}>
                <span style={{ fontSize: '14', color: 'white'}}>New Dark Web post by {msg.author?.replace('(', '')?.replace(')','')} mentionned your organisation.</span>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                    <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                    <span style={{color: 'grey'}}>{created_at && formatTimestamp(created_at)}</span>
                </div>
            </td>       
            <td  className={styles.tableCell} style={{width:160}}>
                <div  onClick={(event)=>{markAsRead(id, event)}} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                     <img src={circlCheck}/>
                    <p style={{fontWeight:400}}>Mark as read</p>
                </div>
            </td>
        </tr>    
            </tbody>
    }
    const renderSurfaceWeb = (msg: any, id:any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList} style={{cursor:"pointer"}} onClick={() => {
            setNamePageLocalStorage("surfaceWeb")
            navigate("/brandIntel")
        }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img src={iconMenu6} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell} style={{padding: 16}}>
                <span style={{ fontSize: '14', color: 'white'}}>{msg.profile_name} mentionned you in a new article: '{msg.post_title ? ` titled '${msg.post_title}.'`:"."}'.</span>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                    <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                    <span style={{color: 'grey'}}>{created_at && formatTimestamp(created_at)}</span>
                </div>
            </td>
            <td  className={styles.tableCell} style={{width:160}}>
                <div  onClick={(event)=>{markAsRead(id, event)}} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                    <img src={circlCheck}/>
                    <p style={{fontWeight:400}}>Mark as read</p>
                </div>
            </td>
        </tr>
            </tbody>
    }
    const renderDomain = (msg: any,id:any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList } style={{cursor:"pointer"}} onClick={() => {
            setNamePageLocalStorage("domains")
            navigate("/brandIntel")
        }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img src={iconMenu4} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell} style={{padding: 16}}>
                <span style={{ fontSize: '14', color: 'white'}}>potential domain phishing attack detected: '{msg.domain}'</span>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                    <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                    <span style={{color: 'grey'}}>{created_at && formatTimestamp(created_at)}</span>
                </div>
            </td>
            <td  className={styles.tableCell} style={{width:160}}>
                <div  onClick={(event)=>{markAsRead(id, event)}} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                    <img src={circlCheck}/>
                    <p style={{fontWeight:400}}>Mark as read</p>
                </div>
            </td>
        </tr>    
            </tbody>
    }
    

    return (
        <div style={{width: "100%", marginTop: 25}}>
         {
            notifsUser.every((not:any) => not.read) === false && notifsUser?.length!== 0?
                <table className={styles.table}>
                    {
                    NotificationUnread.map(item => {
                        if(item.isVul)
                        return renderVul(item.data, item.id,item.created_at)
                        else if(item.isRansome)
                            return renderRansom(item.data,item.id, item.created_at)
                        else if(item.isDarkWeb)
                            return renderDarkweb(item.data, item.id,item.created_at)
                        else if(item.isDomains)
                            return renderDomain(item.data, item.id,item.created_at)
                        else if(item.isSurfaceWeb && item.data?.type?.includes("news"))
                            return renderSurfaceWeb(item.data, item.id,item.created_at)
                        else if(item.isCreditsCard)
                            return renderCreditsCard(item?.data, item.id)
                        else if(item.isCredentials)
                            return renderCredential(item?.data, item.id)
                    })
                    }
                </table>
                :
                <div className={styles.loaderVul}>
                <EmptyStateNot />
                </div>
            }

    </div>
    )
}
