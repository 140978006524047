import axios, { AxiosError } from "axios";
import { env } from "../../../../../environement/env";
import { useQuery } from "@tanstack/react-query";
import { VulnerabilityList } from "../../../../../core/constants";
export interface SortVul {
    sortByDate?: string,
    sortBySeverity?: string,
    sortById?: string,
    search?: string,
    page?: number
}
export const getAllVulnerabilitiesAPI = async (dataSort: SortVul) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/vulnerabilites/get?sortBySeverity=${dataSort.sortBySeverity}&page=${dataSort.page}&search=${dataSort.search}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        console.log({data})
        return data as VulnerabilityList;

    } catch (error) {
        if (error instanceof AxiosError) {
            throw new Error(error.response?.data?.message || 'Failed to fetch vulnerabilites');
        }
        throw error;
    }

}

export default function useGetVulnerabilities({ sortByDate, sortBySeverity, sortById, search, page }: SortVul) {
    return useQuery<VulnerabilityList, Error>({
        queryKey: ['vulnerabilites-query', { sortByDate, sortBySeverity, sortById, search, page }],
        queryFn: () => getAllVulnerabilitiesAPI({ sortByDate, sortBySeverity, sortById, search, page }),
        retry: 2,
        staleTime: 5 * 60 * 1000, // 5 minutes
    });
}