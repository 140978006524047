import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import styles from '../modules.module.scss'
const data = [
  { name: 'Open', value: 1 },
  { name: 'Resolving', value: 2},
  { name: 'Review', value: 1 },
  { name: 'Resolved', value: 2 },
];

const COLORS = ['#e11900', '#00b0ff', '#f5c500', '#20b444' ];

function PieChartThreatManagement() {
    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
          <ResponsiveContainer width="60%" height={200} style={{rotate: "90deg"}}>
        <PieChart  style={{ fontWeight: "semibold", fontSize: "md", pointerEvents: "none" }}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={100}
            dataKey="value"
            innerRadius={68}
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <div className={styles.pieKeys}>
        {
          data?.map((item: any, index:number) => 
            <div key={index} style={{display: 'flex', alignItems: "center", gap: 15}}>
              <div style={{backgroundColor: COLORS[index], height: 8, width: 8, borderRadius: "50%"}}/>
              <p style={{fontSize: 12, color: 'white'}}>{item?.value+" "+item?.name}</p>
            </div>
          )
        }
      </div>
        </div>
  )
}

export default PieChartThreatManagement