import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../../../core/reducers/vulnerabilitiesReducer'
import styles from '../../../modules.module.scss'
import moment from 'moment'

export default function VulInformation() {
  useEffect(() => {
    document.title = "Vulnerability Details - Information"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [])
  
  const { vulDetail } = useSelector(vulnerabilitiesSelector)
    const openlinkDetail = (link: string) => {
      window.open(link, '_blank');
  };
    return (
        <div>
            <table className={styles.table}style={{marginTop:25}}>
    <tbody>
    <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{padding: '16px'}} className={styles.tableCell}>
            Publish Date
          </td>
          <td style={{padding: '16px'}} className={styles.tableCell}>
            <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
            <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/> <span>{moment(vulDetail?.published_date).format("MMMM D, YYYY")}</span>
            </div>
              </td>
        </tr>
        {vulDetail?.last_modified_date && (
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{padding: '16px'}} className={styles.tableCell}>
          Last Modified
          </td>
          <td style={{padding: '16px'}} className={styles.tableCell}>
          <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
            <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/> <span>{moment(vulDetail?.last_modified_date).format("MMMM D, YYYY")}</span>
            </div>
              </td>
        </tr>
        )}
        
        {vulDetail?.cve_id && (
        <tr  className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '100px', padding: '16px'}} className={styles.tableCell}>
          NVD Link
          </td>
              <td style={{padding: '16px', color: "#e9bd08", textDecorationLine: 'underline'}} className={styles.tableCell}>
                <span onClick={()=>openlinkDetail(vulDetail?.link || "") } style={{cursor:"pointer"}}>{
                    vulDetail?.cve_id
                }</span>
              </td>
        </tr>
        )}

        {vulDetail?.problem_type_data && (
        <tr  className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '100px', padding: '16px'}} className={styles.tableCell}>
          Weaknesses
          </td>
              <td style={{padding: '16px', color: "#e9bd08", textDecorationLine: 'underline'}} className={styles.tableCell}>
                <span onClick={()=>openlinkDetail(vulDetail?.weakness_link || "") } style={{cursor:"pointer"}}>{
                    vulDetail?.problem_type_data
                }</span>
              </td>
        </tr>
        )}

        {vulDetail?.source && (
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{padding: '16px'}} className={styles.tableCell}>
          Source
          </td>
          <td style={{padding: '16px'}} className={styles.tableCell}>
               {
                vulDetail?.source?.split("@")[1]
               }
            </td>
        </tr>
        )}

        {vulDetail?.vector_string && (
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{padding: '16px'}} className={styles.tableCell}>
          Vector String
          </td>
          <td style={{padding: '16px'}} className={styles.tableCell}>
               {
                vulDetail?.vector_string
               }
            </td>
        </tr>
        )}

        {vulDetail?.description && (
        <tr style={{verticalAlign: 'top'}} className={styles.tableActivityRowRansomWatchList}>
          <td style={{padding: '16px'}} className={styles.tableCell}>
          Description
          </td>
          <td style={{padding: '16px', lineHeight: 1.5}} className={styles.tableCell}>
               {
                vulDetail?.description
               }
            </td>
        </tr>
        )}
        
      </tbody>
  </table>
        </div>
    )
}
