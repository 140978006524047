import React, { useState, useEffect } from 'react'
import styles from '../../../modules.module.scss'
import { getWhoisDomaineApi } from '../../../../../core/api/brandIntelApi'
import { useSelector } from 'react-redux'
import { brandIntelSelector } from '../../../../../core/selectors/brandIntelSelector'
import { CircularProgress } from '@mui/material'
import moment from 'moment'

// Define the type for the parsed WHOIS data
interface Whois {
    domain_name: string;
    registry_domain_id: string;
    registry_whois_server: string;
    updated_date: string;
    creation_date: string;
    registry_expiry_date: string;
    registrar_registration_expiration: string;
    domain_status: string;
    registry_registrant_id: string;
    registrant_name: string;
    registrant_organization: string;
    registrant_street: string;
    registrant_city: string;
    registrant_state: string;
    registrant_postal_code: string;
    registrant_country: string;
    registrant_phone: string;
    registrant_fax: string;
    registrant_email: string;
    registrar: string;
    registrar_abuse_email: string;
    registrar_abuse_phone: string;
    name_servers: string[];
    dnssec: string;
    raw_text: string;
}

interface DomainData {
    domain: string;
    elapsed: number;
    whois: Whois;
}

function WhoisRecords() {
    const { domaineDetail } = useSelector(brandIntelSelector)
    const [response, setResponse] = useState<DomainData | null>(null)

    useEffect(() => {
        const getWhoisRecord = async () => {
            if (domaineDetail) {
                const data = await getWhoisDomaineApi(domaineDetail.domain)
                setResponse(data)
            }
        }
        getWhoisRecord()
    }, [])

   
    if (!response) {
        return <div className={styles.loaderVul}>
        <CircularProgress />
        </div>
    }


    return (
        <table className={styles.table} style={{ marginTop: 25 }}>
            <tbody>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Domain Name</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.whois.domain_name}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Domain Status</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.whois.domain_status}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Registrar</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.whois.registrar}</p>
                    </td>
                </tr>
                {/* <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Registrar URL</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}></p>
                    </td>
                </tr> */}
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Registrar Abuse Email</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.whois.registrant_email}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Registrar Abuse Phone</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.whois.registrant_phone}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Creation Date</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{moment(response.whois.creation_date).format("MMMM DD, YYYY")}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Updated Date</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{moment(response.whois.updated_date).format("MMMM DD, YYYY")}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Registry Expiry Date</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.whois.registry_expiry_date}</p>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default WhoisRecords
