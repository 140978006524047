import React, { useEffect, useState } from 'react';
import {useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import styles from '../../modules.module.scss';
import { CircularProgress, MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material';
import ReactGA from 'react-ga4';
import EmptyStateVul from '../../common/EmptyStateVul'
import useGetVulnerabilities from './hooks/useGetvulnerabilites';
import SearchField from '../../components/searchField/SearchField';
import { PageSort } from '../../../../core/api/vulnerabilityApi'
import { GET_ALL_VULNERABILITIES } from '../../../../core/constants';
import { SET_VUL_DETAIL } from '../../../../core/constants';

type SortOrder = 'asc' | 'desc';

function ListVulnerabilities() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [severity, setSeverity] = useState('all');
    const [search, setsearch] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [isSortingById, setIsSortingById] = useState(false);
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const [page, setPage] = useState(1)

    const { data, isLoading, isError } = useGetVulnerabilities({
        sortBySeverity: severity == 'all' ? '' : severity,
        search: searchQuery,
        page: page
    })

    const handleSearch = () => {
        setSearchQuery(search);
        const dataSortVul: PageSort = {
            page: page,
            sortByDate: "all",
            sortBySeverity: "all",
            sortById: "all",
            search: search
        }
        dispatch({ type: GET_ALL_VULNERABILITIES, payload: dataSortVul })
    };

    const handleChangeSeverity = (event: SelectChangeEvent) => {
        setPage(1);
        setSeverity(event.target.value as string);
    };

    const renderColors = (riskScore: number) => {
        if (riskScore === 0.0) return { textColor: "#757575", backgroundColor: "#E6E6E6", borderColor: "#757575" };
        if (riskScore >= 0.1 && riskScore <= 3.9) return { textColor: "#20b444", backgroundColor: "#ecf9ef", borderColor: "#20b444" };
        if (riskScore >= 4.0 && riskScore <= 6.9) return { textColor: "#A08100", backgroundColor: "#FFEB96", borderColor: "#D1A800" };
        if (riskScore >= 7.0 && riskScore <= 8.9) return { textColor: "#894C02", backgroundColor: "#FFD8A7", borderColor: "#894C02" };
        return { textColor: "#e11900", backgroundColor: "#FBD2C4", borderColor: "#e11900" };
    };

    const handleSort = () => {
        setIsSortingById(!isSortingById);
    };

    useEffect(() => {
        document.title = "Vulnerabilities";

        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    let uniqueData = data?.cves?.filter(
        (item, index, self) =>
            index === self.findIndex((t) => t.cve_id === item.cve_id)
    ).sort((a, b) => {
        if (isSortingById) {
            //@ts-ignore
            const [, yearA, numA] = a.cve_id.match(/CVE-(\d+)-(\d+)/).map(Number);
            //@ts-ignore
            const [, yearB, numB] = b.cve_id.match(/CVE-(\d+)-(\d+)/).map(Number);
            if (yearA !== yearB) {
                return yearA > yearB ? 1 : -1
            } else {
                return numA > numB ? 1 : -1
            }
        }
        return b.published_date.localeCompare(a.published_date)
    });

    const handleChangePage = (
        _: React.ChangeEvent<unknown>, value: number
    ) => {

        setPage(value)
    };

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, height: 45, gap: 16 }}>
                <SearchField
                    search={search}
                    setsearch={setsearch}
                    handleSearch={handleSearch}
                />
                <div style={{ display: 'flex', alignItems: 'center', gap: 10, color: "white", fontSize: "14px" }}>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={severity} onChange={handleChangeSeverity} style={{ fontSize: 14, fontWeight: 500 }} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginLeft: '-20px' }} />}

                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    backgroundColor: '#1f1f1f',
                                    borderRadius: '8px',
                                    padding: '0px',
                                    boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                                    maxHeight: '360px',
                                    overflowY: 'auto',
                                },
                            },
                            MenuListProps: {
                                dense: false,
                                disablePadding: true,
                            },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                        }}

                    >
                        <MenuItem value={'all'} disabled={severity === 'severity'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>All Risks</MenuItem>
                        <MenuItem value={'CRITICAL'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Critical</MenuItem>
                        <MenuItem value={'HIGH'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>High</MenuItem>
                        <MenuItem value={'MEDIUM'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Medium</MenuItem>
                        <MenuItem value={'LOW'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Low</MenuItem>
                    </Select>
                </div>
            </div>

            {isLoading ? (
                <div className={styles.loaderVul}>
                    <CircularProgress />
                </div>
            ) : uniqueData?.length !== 0 ? (
                <>
                    <table className={styles.table}>
                        <thead>
                            <tr className={styles.tableHead}>
                                <th style={{ paddingLeft: 16, display: 'flex', alignItems: "center", gap: 5, cursor: "pointer" }} onClick={() => handleSort()}>
                                    <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                                        <span style={{ marginRight: '4px' }}>CVE</span>
                                        <FontAwesomeIcon icon={faChevronDown} size="1x" height={12} width={11} style={{ transform: !isSortingById ? 'rotate(0deg)' : 'rotate(180deg)', }} />
                                    </p>
                                </th>
                                <th style={{ paddingLeft: 8 }}>
                                    <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                                        <span style={{ marginRight: '4px' }}>Description</span>
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {uniqueData?.map((item) => (
                                <tr style={{ cursor: 'pointer' }} className={styles.tableActivityRowWatchList} onClick={() => {
                                    dispatch({ type: SET_VUL_DETAIL, payload: item });
                                    navigate("/vulnerabilities/vulnerabilityDetail");
                                }}>
                                    <td style={{ width: 140, paddingLeft: 16 }} className={styles.tableCell}>{item.cve_id}</td>
                                    <td className={styles.tableCell} style={{ padding: "16px 8px" }}>
                                        <span style={{ fontSize: '14', color: "white", lineHeight: 1.5 }}>{item?.description?.substring(0, 260)}...</span>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 16, gap: 5 }}>
                                                <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey' />
                                                <span style={{ color: 'grey' }}>Published: {moment(item?.published_date).format("MMM D, YYYY - HH:mm")}</span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 16, gap: 5 }}>
                                                <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey' />
                                                <span style={{ color: 'grey' }}>Last Modified: {moment(item?.last_modified_date).format("MMM D, YYYY - HH:mm")}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={styles.tableCell} style={{ paddingRight: 32 }}>
                                        {item?.base_score ? (
                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                                <span className={styles.scoreSeverity} style={{
                                                    width: '22px',
                                                    color: renderColors(item?.base_score).textColor,
                                                    backgroundColor: renderColors(item?.base_score).backgroundColor,
                                                    borderColor: renderColors(item?.base_score).borderColor
                                                }}>
                                                    {item?.base_score}
                                                </span>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                                <span className={styles.scoreUserInt} style={{ width: '22px', padding: '2px 6px', color: "" }}>
                                                    -
                                                </span>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        showFirstButton
                        showLastButton
                        shape="rounded"
                        onChange={handleChangePage}
                        page={Number(data?.pagination?.currentPage)}
                        count={data?.pagination?.totalPages}
                        sx={{
                            '& .MuiPagination-ul': {
                                justifyContent: "center",
                                display: 'flex'
                            },
                            '& .Mui-selected': {
                                color: '#ffffff',
                                backgroundColor: '#1f1f1f',
                                opacity: 0.3,
                            },
                            '& .MuiPaginationItem-root': {
                                color: '#ffffff',
                            },
                        }}
                    />
                </>
            ) : (
                <div className={styles.loaderVul}>
                    <EmptyStateVul />
                </div>
            )}
        </div>
    )
}

export default ListVulnerabilities;


