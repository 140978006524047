import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../../../core/reducers/vulnerabilitiesReducer'
import styles from '../../../modules.module.scss'
import moment from 'moment'
import { VulnerabilityRef } from '../../../../../core/constants'
import EmptyStateVulReference from '../../../common/EmptyStateVulReference'


export default function Reference() {
    useEffect(() => {
        document.title = "Vulnerability Details - References"
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, [])

    const { vulnerabilies, vulDetail } = useSelector(vulnerabilitiesSelector)
    
    const references = vulnerabilies?.references?.filter(item => 
        item?.cve_id === vulDetail?.cve_id
    ) || [];

    const uniqueReferences = references?.filter((item, index, self) =>
        index === self.findIndex(ref => ref?.url === item?.url)
    ) || [];

    const openlinkDetail = (link: string) => {
        window.open(link, '_blank');
    };

    const filteredReferences = vulnerabilies?.references?.map(item => item?.cve_id);
    // console.log("References:", filteredReferences);

    return (
            <>
            {uniqueReferences && uniqueReferences.length > 0 ? (
            <table className={styles.table}style={{marginTop:25}}>
                <tbody>                
                    {
                        uniqueReferences?.map((item) => 
                            <tr className={styles.tableActivityRowRansomWatchList} style={{justifyContent: 'space-between'}}>
                                <td style={{width: '100%', padding: '16px', color: "#e9bd08", textDecorationLine: 'underline', cursor:'pointer'}} className={styles.tableCell} onClick={()=>openlinkDetail(item?.url)} >
                                {item?.url?.length > 70 ? item?.url.slice(0, 70) + "..." : item?.url}
                                </td>
                                <td style={{padding: '0px 16px'}} className={styles.tableCell}>
                                    <div style={{display: 'flex', alignItems: "center", gap: 5, justifyContent: 'flex-end'}}>
                                        <span style={{whiteSpace: 'nowrap', backgroundColor: '#2C2C2C', padding: "6px 12px", borderRadius: "32px", color: 'white'}}>{item?.tags ? item?.tags : "No Tags"}</span>
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
 ) : (
    <EmptyStateVulReference />
  )}
</>
    )
}
