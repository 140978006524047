import React from 'react'
import styles from '../../modules.module.scss'
import { faCalendar, faChevronDown, faEllipsisVertical, faListDots, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

function ArchivesSection() {
  const navigate = useNavigate()
  return (
    <div style={{marginTop: 20}}>
    <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between"}}>
        <div className={styles.searchAndIcon}>
            <input className={styles.search} name='search' placeholder='Search'/>
            <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
        </div>
        <div style={{display: 'flex', alignItems: "center", gap: 25}}>
        <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
            <span>All types </span>
            <FontAwesomeIcon icon={faChevronDown} size="1x" height={12} width={11}/>
        </p>
        <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
            <span>All threats </span>
            <FontAwesomeIcon icon={faChevronDown} size="1x" height={12} width={11}/>
        </p>
        <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
            <span>Added today </span>
            <FontAwesomeIcon icon={faChevronDown} size="1x" height={12} width={11}/>
        </p>
    </div>
    </div>
    <div style={{width: "100%"}}>
    <table className={styles.table}>
                <tr className={styles.tableHead}>
                  <th style={{paddingLeft: 8}}>
                      <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                            <span>ID </span>
                            <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                        </p>
                </th>
                  <th style={{paddingLeft: 8}}>
                  <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                            <span>Description </span>
                            <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                        </p>
                  </th>
                  <th style={{paddingLeft: 8}}>
                  <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                            <span>Assigned to </span>
                            <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                        </p>
                  </th>
                  <th style={{paddingLeft: 8}}>
                  <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                            <span>Risk Score </span>
                            <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                        </p>
                  </th>
                  <th style={{paddingLeft: 8}}>
                  <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                            <span>Due Date </span>
                            <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                        </p>
                  </th>
                </tr>
                  <tr  className={styles.tableActivityRowWatchListAssets} onClick={()=>navigate("/threatManagement/archiveDetail")}>
                  <td className={styles.tableCell}>
                  DEF-2024-0021
                  </td>
                    <td  className={styles.tableCell}>
                    Abyss - 127 Exposed Credit Cards
                    </td>
                    <td  className={styles.tableCell}>
                        <div style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                            <img src="/profile.png" height={24} width={24} style={{borderRadius: '50%'}}/>
                            <p style={{color: 'white', fontSize: 14}}>Not Assigned</p>
                        </div>
                    </td>
                    <td className={styles.tableCell} >
                      <p className={styles.riskScore} >Critical</p>
                    </td>
                    <td className={styles.tableCell} style={{display: 'flex', alignItems: "center",justifyContent:"space-between", padding:8}}>
                        <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                            <FontAwesomeIcon icon={faCalendar} size='1x' height={12} width={11}/>
                            <p style={{fontSize: 14}}>April 24, 2024</p>
                        </div>
                        <FontAwesomeIcon icon={faEllipsisVertical} size='1x' height={12} width={11}/>
                    </td>
                  </tr>
            </table>
            </div>
</div>
  )
}

export default ArchivesSection