import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resePasswordRequest } from '../../core/actions/authActions';
import { authSelector } from '../../core/reducers/authReducer';
import ErrorNotification from '../settings/common/errorNotification';
import ReactGA from 'react-ga4';
import styles from './auth.module.scss';

import Logo from '../../icons/main/identity/defendisWhite.svg';
import AuthFooter from './components/authFooter';

// Types and Interfaces
interface FormData {
    email: string;
}

interface FormError {
    email: string;
}

interface AuthState {
    resetMailIsSent: boolean;
    notification: string;
}

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(authSelector) as AuthState;

    // Form state
    const [formData, setFormData] = useState<FormData>({
        email: ''
    });
    const [formError, setFormError] = useState<FormError>({
        email: ''
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [openNotification, setOpenNotification] = useState<boolean>(false);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        document.title = "Recover Password";
        if (auth.resetMailIsSent) {
            setOpenNotification(true);
        }
    }, [auth.resetMailIsSent]);


    // Validation function
    const validateEmail = (email: string): string => {
        if (!email) {
            return 'Email is required';
        }
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
            return 'Invalid email address';
        }
        // Additional validation for work email (optional)
        const commonPersonalDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
        const domain = email.split('@')[1]?.toLowerCase();
        if (domain && commonPersonalDomains.includes(domain)) {
            return 'Please use your work email address';
        }
        return '';
    };

    // Handle input change
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData({ email: value });

        // Real-time validation
        const error = validateEmail(value);
        setFormError({ email: error });
    };

    // Handle form submission
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        const error = validateEmail(formData.email);
        setFormError({ email: error });

        if (!error) {
            dispatch(resePasswordRequest({ email: formData.email }));
            // Reset form after successful submission
            setFormData({ email: '' });
        }

        setIsSubmitting(false);
    };

    const handleCloseNotification = (): void => {
        setOpenNotification(false);
    };

    const handleGoBack = (): void => {
        navigate(-1);
    };

    return (
        <div style={{ backgroundColor: '#141414', width: '100vw', height: '100vh', position: 'relative' }}>
            <div
                style={{ position: 'absolute', top: 0, left: 0, padding: '6px 0px', cursor: 'pointer' }}
                onClick={() => navigate('/')}
            >
                <img src={Logo} className={styles.logo} alt="Defendis Logo" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '95vh' }}>
                <div className={styles.login} style={{ width: 'auto' }}>
                    <div style={{ paddingTop: '3rem', padding: '4rem' }}>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.loginInputs}>
                                <span className={styles.titleLogin}>Recover Account</span>
                                <TextField
                                    InputProps={{ sx: { fontSize: 14 } }}
                                    onChange={handleChange}
                                    id="email"
                                    name="email"
                                    label="Work Email"
                                    variant="standard"
                                    fullWidth
                                    margin="normal"
                                    value={formData.email}
                                    error={!!formError.email}
                                    helperText={formError.email}
                                    disabled={isSubmitting}
                                />
                                <div style={{ display: 'grid', width: '100%', gap: 0 }}>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting || !!formError.email}
                                        style={{
                                            marginTop: 48,
                                            fontSize: 14,
                                            fontWeight: 500,
                                            opacity: (isSubmitting || !!formError.email) ? 0.7 : 1
                                        }}
                                    >
                                        Recover Account
                                    </Button>
                                    <Button
                                        onClick={handleGoBack}
                                        type="button"
                                        style={{
                                            marginTop: 12,
                                            backgroundColor: '#1F1F1F',
                                            color: 'white',
                                            fontSize: 14,
                                            fontWeight: 500,
                                            width: '100%'
                                        }}
                                    >
                                        Go back
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', gap: 16, justifyContent: 'center', alignItems: 'center' }}>
                <AuthFooter />
            </div>
            {auth.resetMailIsSent && (
                <ErrorNotification
                    severity="success"
                    open={openNotification}
                    message={auth.notification}
                    handleClose={handleCloseNotification}
                />
            )}
        </div>
    );
};

export default ForgotPassword;