
import styles from "./../../../../views/modules/modules.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';


function SearchField({ search, setsearch, handleSearch }: any) {
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
    return (
        <div className={styles.searchAndIcon}>
            <input
                value={search}
                className={styles.search}
                name="search"
                placeholder="Search"
                onChange={(e) => setsearch(e.target.value)}
                onKeyDown={handleKeyPress}
            />
            <FontAwesomeIcon
                className={styles.searchIcon}
                style={{ cursor: 'pointer' }}
                color={search.trim() !== '' ? "#ffffff" : "#333333"}
                icon={faSearch}
                size="1x"
                onClick={handleSearch}
            />
        </div>
    )
}

export default SearchField