import { faArrowLeft, faCalendar, faChevronDown, faEllipsisVertical, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Comments from '../../components/Comments'
import Header from '../../common/Header'
import Menu from '../../common/Menu'
import styles from '../../modules.module.scss'

interface props {
    grid: boolean
}

function ThreatsSection({grid}: props) {
    const navigate = useNavigate()
  return (
    <div style={{marginTop: 20}}>
    <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between"}}>
        <div className={styles.searchAndIcon}>
            <input className={styles.search} name='search' placeholder='Search'/>
            <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
        </div>
        <div style={{display: 'flex', alignItems: "center", gap: 25}}>
        <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
            <span>All types </span>
            <FontAwesomeIcon icon={faChevronDown} size="1x" height={12} width={11}/>
        </p>
        <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
            <span>All threats </span>
            <FontAwesomeIcon icon={faChevronDown} size="1x" height={12} width={11}/>
        </p>
        <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
            <span>Added today </span>
            <FontAwesomeIcon icon={faChevronDown} size="1x" height={12} width={11}/>
        </p>
    </div>
    </div>
    {
        !grid ? 
        <div style={{width: "100%"}}>
        <table className={styles.table}>
          <tr className={styles.tableHead}>
            <th style={{paddingLeft: 8}}>
                <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                      <span>ID </span>
                      <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                  </p>
          </th>
            <th style={{paddingLeft: 8}}>
            <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                      <span>Description </span>
                      <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                  </p>
            </th>
            <th style={{paddingLeft: 8}}>
            <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                      <span>Assigned to </span>
                      <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                  </p>
            </th>
            <th style={{paddingLeft: 8}}>
            <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                      <span>Risk Score </span>
                      <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                  </p>
            </th>
            <th style={{paddingLeft: 8}}>
            <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                      <span>Status </span>
                      <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                  </p>
            </th>
            <th style={{paddingLeft: 8}}>
            <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                      <span>Due Date </span>
                      <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                  </p>
            </th>
          </tr>
            <tr  className={styles.tableActivityRowDomainBrand} onClick={()=>navigate("/threatManagement/threatDetail")}>
            <td  className={styles.tableCell}>
            DEF-2024-0021
            </td>
              <td  className={styles.tableCell}>
              Abyss - 127 Exposed Credit Cards
              </td>
              <td  className={styles.tableCell}>
                  <div style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                      <img src="/profile.png" height={24} width={24} style={{borderRadius: '50%'}}/>
                      <p style={{color: 'white', fontSize: 14}}>Not Assigned</p>
                  </div>
              </td>
              <td  className={styles.tableCell} >
                <p className={styles.riskScore} >Critical</p>
              </td>
              <td  className={styles.tableCell} >
                <p style={{backgroundColor: '#e11900', color: 'white', borderRadius: 32, width: 'fit-content', textAlign: 'center', padding: '4px 8px'}} >Open</p>
              </td>
              <td className={styles.tableCell} style={{display: 'flex', alignItems: "center",justifyContent:"space-between", padding:8}}>
                  <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                      <FontAwesomeIcon icon={faCalendar} size='1x' height={12} width={11}/>
                      <p style={{fontSize: 14}}>April 24, 2024</p>
                  </div>
                  <FontAwesomeIcon icon={faEllipsisVertical} size='1x' height={12} width={11}/>
              </td>
            </tr>
      </table>
      </div>
      : 
      <div className={styles.gridScrum}>
      <div style={{width:'100%', height: "78vh", borderRadius:8, backgroundColor: '#141414', padding:'0px 16px 16px 16px'}}>
          <p style={{color: 'white', fontSize: 14, fontWeight: "500"}}>Open</p>
          <div style={{ backgroundColor: '#1a1a1a', border: '1px solid #242424', borderRadius: 8, padding: 16, }}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: -10}}>
                  <img src='/profile.png' height={24} width={24} style={{borderRadius: '50%'}}/>
                  <p className={styles.riskScore}>Critical</p>
              </div>
              <div style={{width: '100%', marginTop: 8}}>
              <span style={{color: 'white', fontSize: 16,lineHeight:'1.4em'}}>Omega - 65 Exposed Credit Cards</span>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 12}}>
                  <span style={{color: '#757575', fontSize: 12}}>May 12, 2023</span>
                  <span  style={{color: '#757575', fontSize: 12}}>Leak</span>
              </div>
              </div>
              
          </div>
      </div>
      <div style={{width:'100%', height: "78vh",padding:'0px 16px 16px 16px', borderRadius:8, backgroundColor: '#141414'}}>
          <p style={{color: 'white', fontSize: 14, fontWeight: "500"}}>Resolving</p>
      </div>
      <div style={{width:'100%', height: "78vh",padding:'0px 16px 16px 16px', borderRadius:8, backgroundColor: '#141414'}}>
          <p style={{color: 'white', fontSize: 14, fontWeight: "500"}}>Review</p>
      </div>
      <div style={{width:'100%', height: "78vh",padding:'0px 16px 16px 16px', borderRadius:8, backgroundColor: '#141414'}}>
          <p style={{color: 'white', fontSize: 14, fontWeight: "500"}} >Resolved</p>
      </div>
    </div>
    }
</div>
  )
}

export default ThreatsSection