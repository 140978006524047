import React from 'react'
import styles from '../../../modules.module.scss'
function RansomeNote() {
    return (
        <table className={styles.table}style={{marginTop:25}}>
        <thead>
          <tr className={styles.tableHead}>
          <th style={{paddingLeft: 8}}>
              Note
          </th>
          
          </tr>
        </thead>
        <tbody>
            <tr className={styles.tableActivityRowRansom1Col}>
              <td style={{width: '848px', padding: '10px 16px', color: "#e9bd08"}} className={styles.tableCell}>
              1 ransom note
              </td>
              </tr>
            
          
          </tbody>
      </table>
    )
}

export default RansomeNote
