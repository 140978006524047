import React from 'react'
import styles from "../modules.module.scss"
import EmptyStateLogo from "../../../icons/main/states/emptyStateUsers.svg"

interface props {
    text: string
}
export default function EmptyStateVictims() {
  return (
    <div className={styles.emptyState}>
    <div style={{width: '320px', margin: 32}}>
        <div style={{display: "flex", justifyContent:"center",alignItems: "center"}}>
          <img src={EmptyStateLogo}/>
        </div>
        <div style={{marginTop: 40}}> 
          <h2 style={{textAlign: "center",color:"white", fontWeight:"600", fontSize:20, marginBottom: 0}}>No Victims Detected</h2>
          <p style={{textAlign: "center",color:"#AFAFAF", fontWeight:'400', fontSize: 14, marginBottom: 16}}>The latest detected victims will be shown here when available.</p>
        </div>
    </div>
  </div>
  )
}
