import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import styles from '../modules.module.scss'

interface props {
    Executives: number;
    Internal: number;
    External: number;
}

const COLORS = ['#e11900', 'rgba(255,25,0,0.6)', 'rgba(255,25,0,0.2)' ];

function PIeChartHomeTypeUser({Executives, Internal, External}:props) {
  const data = [
    { name: 'Executives', value: Executives },
    { name: 'Internal', value: Internal },
    { name: 'External', value: External },
  ];
    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
          <ResponsiveContainer width="50%" height={200} style={{rotate: "270deg"}}>
        <PieChart  style={{ fontWeight: "semibold", fontSize: "md", pointerEvents: "none" }}>
          <Pie
              data={data}
              startAngle={0}
              endAngle={-360}
              cx="50%"
              cy="50%"
              outerRadius={100}
              dataKey="value"
              innerRadius={70}
              stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <div className={styles.pieKeys}>
        {
          data?.map((item: any, index:number) => 
            <div key={`legend-${index}`} style={{display: 'flex', alignItems: "center", gap: 15}}>
              <div style={{backgroundColor: COLORS[index], height: 8, width: 8, borderRadius: "50%"}}/>
              <p style={{ fontSize: 12 }}>{item?.value.toLocaleString() + " " + (item?.value === 1 ? item?.name === 'Executives' ? 'Executive' : item?.name === 'Internal' ? 'Internal' : 'External' : item?.name)}
</p>
            </div>
          )
        }
      </div>
        </div>
  );
}

export default PIeChartHomeTypeUser;
