import axios from "axios";
import { VulnerabilityList, Page, CVEItem, Vulnerability, SortVul } from "../constants";
import { env } from "../../environement/env";

export interface PageSort {
    page: number,
    sortByDate: string | null,
    sortBySeverity: string | null,
    sortById: string | null,
    search?: string
}

export const getAllVulnerabilitiesAPI = async (dataSort: SortVul) => {
        const { data } = await axios.get(`${env.apiUrl}/vulnerabilites/get?sortByDate=${dataSort.sortByDate}&sortBySeverity=${dataSort.sortBySeverity}&sortById=${dataSort.sortById}&page=${dataSort.page}&search=${dataSort.search}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as VulnerabilityList;
}

export const getOverviewVulAPI = async (sortByDate: string) => {
    const { data } = await axios.get(`${env.apiUrl}/vulnerabilites/stats?sortByDate=${sortByDate}`, {
        headers: {
            'Content-Type': 'application/json',
            'x-requested-by': 'ci-app',
            'Origin': false
        }
    })
    return data as VulnerabilityList;
}


export const getVulnerabilitieByCVEAPI = async (query: string) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/vulnerabilites/getByCVE?cveId=${query}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as Vulnerability;
    } catch (error) {
        throw  error;
    }
}

export const cvesFromWatchListAPI = async (cveIds:any[]) => {
    try {
        const { data } = await axios.post(`${env.apiUrl}/vulnerabilites/cvesFromWatchList`,
            {cveIds},
             {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        throw  error;
    }
}