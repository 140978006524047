import React, { useState } from 'react'
import Header from '../common/Header'
import Menu from '../common/Menu'
import styles from '../modules.module.scss'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useDispatch } from 'react-redux'
import WeeklyReports from './reportsSections/WeeklyReports'
import { useNavigate } from 'react-router-dom'
import GenerateReports from './reportsSections/GenerateReports'
export default function ReportsView() {
    const navigate = useNavigate()
    const [recent, setRecent] = useState('all')
  const [activeCompo, setactiveCompo] = useState('weeklyReports')
    const dispatch = useDispatch()
  const handleChange = (event: SelectChangeEvent) => {
        setRecent(event.target.value as string);
      }

    const renderSections = () => {
        switch (activeCompo) {
            case 'weeklyReports':
                return <WeeklyReports/>
            case 'generateReports':
              return <GenerateReports/>
            default:
                return;
        }
    }

  return (
    <div className={styles.main}>
            <Header/>
            <Menu/>
            <div className={styles.content}>
            <div className={styles.menuContent}>
            <div className={styles.firstSectionMenu}>
                <p style={{cursor: 'pointer'}} onClick={()=>setactiveCompo('weeklyReports')} className={activeCompo === 'weeklyReports' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Weekly Reports</p>
                <p style={{cursor: 'pointer'}} onClick={()=>setactiveCompo('generateReports')} className={activeCompo === 'generateReports' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Generate Report</p>
            </div>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recent}
                onChange={handleChange}
                style={{fontSize: 14, fontWeight: '500'}}
            >
                <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'last7Days'}>Last 7 days</MenuItem>
                        <MenuItem value={'lastMonth'}>Last month</MenuItem>
                        <MenuItem value={'last3Months'}>Last 3 months</MenuItem>
            </Select>
        </div>
              <hr className={styles.divider}/>
              {
                renderSections()
              }
            </div>
    </div>
    
  )
}
