import React, { useEffect, useState } from 'react'
import {  MenuItem, Select, SelectChangeEvent } from '@mui/material'
import Header from '../../modules/common/Header'
import Menu from '../common/Menu'
import styles from '../modules.module.scss'
import OverviewSection from './brandTabs/brandDashboard'
import DarkwebSection from './brandTabs/brandDark'
import DomainsSection from './brandTabs/brandDomains'
import SocialMediaSection from './brandTabs/brandSocial'
import SurfaceWebSection from './brandTabs/brandSurface'
import PlacesSection from './brandTabs/brandPlaces'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { REQUEST_DARK, REQUEST_DOMAINE, REQUEST_PLACES, REQUEST_SOCIALMEDIA, REQUEST_SURFACEWEB, SET_STEP_PAGE } from '../../../core/constants'
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector'
import { setNamePageLocalStorage } from '../../../core/actions/stepsNavPageActions'

const nameSteps = [
    "overview",
    "darkWeb",
    "domains",
    "socialMedia",
    "surfaceWeb",
    "places"
]

function BrandIntelView() {
    const dispatch = useDispatch()
    const [recent, setRecent] = useState('all')
    const storedStepName = localStorage.getItem('step') || 'darkWeb';
    const [activeCompo, setactiveCompo] = useState(storedStepName)
    const { dark, step } = useSelector(brandIntelSelector)
    
      const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value
          switch (activeCompo) {
            case "darkWeb":
                dispatch({
                    type: REQUEST_DARK,
                    payload: value
                })
                break;
            case "domains":
                    dispatch({
                        type: REQUEST_DOMAINE,
                        payload: value
                    })
                    break; 
            case 'surfaceWeb':
                dispatch({ 
                    type: REQUEST_SURFACEWEB,
                    payload: value
                }); 
                break;
            case 'socialMedia':
                dispatch({
                    type: REQUEST_SOCIALMEDIA,
                    payload: value
                  })
                  break;
            default:
                break;
          }
          setRecent(value)
        }
        
    const handleChangeStep = (newStepName: string) => {
        setNamePageLocalStorage(newStepName)
        setactiveCompo(newStepName);
      };
      
      
      useEffect(() => {
        
        if(nameSteps.includes(storedStepName))
            setactiveCompo(storedStepName);
          else
            setactiveCompo("darkWeb")
        setRecent('all')
        
      }, [storedStepName]);
    const renderSection = () => {
        switch (activeCompo) {
            // case 'overview':
            //     return <OverviewSection/>
            case 'darkWeb':
                
                return <DarkwebSection/>
            case 'domains':
                return <DomainsSection/>
            case 'socialMedia':
                return <SocialMediaSection/>
            case 'surfaceWeb':
                return <SurfaceWebSection/>
            case 'places':
                return <PlacesSection/>
            default:
                return <DarkwebSection/>
        }
    }
   
   
    
    
    return (
        <div className={ styles.main }>
            <Menu/>
            <Header/>
            <div className={styles.content}>
            <div className={styles.menuContent}>
            <div className={styles.firstSectionMenu}>
                <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('darkWeb')} className={activeCompo === 'darkWeb' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Dark Web</p>
                <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('domains')} className={activeCompo === 'domains' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Domains</p>
                <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('socialMedia')} className={activeCompo === 'socialMedia' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Social Media</p>
                <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('surfaceWeb')} className={activeCompo === 'surfaceWeb' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Surface Web</p>
                {/* <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('places')} className={activeCompo === 'places' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Places</p> */}
            </div>
             {
                activeCompo !== "places" && <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recent}
                onChange={handleChange}
                style={{fontSize: 14, fontWeight: '500'}}
            >
                <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'last7days'}>Last 7 days</MenuItem>
                        <MenuItem value={'lastMonths'}>Last month</MenuItem>
                        <MenuItem value={'last3Months'}>Last 3 months</MenuItem>
            </Select>
             }
        </div>
              <hr className={styles.divider}/>
              {
                  renderSection()
              }
            </div>
        </div>
    )
}

export default BrandIntelView
