import React, {FormEvent, useState, useEffect} from 'react'
import Header from '../modules/common/Header'
import Menu from './common/settingsMenu'
import styles from './settings.module.scss'
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { Theme } from '@emotion/react'
import { createStyles, makeStyles } from '@mui/styles';
import { typesHandleChange } from './common/constants'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Entreprise, UserInfo } from '../../core/constants'
import ErrorNotification from './common/errorNotification'
import { entrepriseSelector } from '../../core/reducers/entrepriseReducer'
import { updateInfosEntreprise } from '../../core/actions/entrepriseActions'
import HeaderEntreprise from './components/headers/headerEntreprise'
import ReactGA from 'react-ga4';
import AvatarUploadEntreprise from './components/avatarUpload/avatarUploadEntreprise'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidEmail: {
      borderBottom: '1px solid red !important',
    },
    validEmail: {
        borderBottom: '1px solid green !important',
    },
    disabledInput :{
      color: "#333333"
    }
  }),
);

const EntrepriseSettings = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    //const state: any = useSelector((state) => state)
    //const { entreprise } = state.entreprise
    const entreprise = useSelector(entrepriseSelector)
    const [recent, setRecent] = useState('10')
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [emailSecondary, setEmailSecondary] = useState<string>('');
    const [errorSecondary, setErrorSecondary] = useState<boolean>(false);
    const [formState, setformState] = useState<Entreprise>(entreprise);
    const [openNotification, setopenNotification] = useState(false)
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean>(false)
   


    const validateEmail = (email: string): boolean => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    };

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEmail(value);
        setError(!validateEmail(value));
    };

    const handleChangeEmailSecondary = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEmailSecondary(value);
        setErrorSecondary(!validateEmail(value));
    };


    // const handleChange = (event: SelectChangeEvent) => {
    //     setRecent(event.target.value as string);
    //   };

    const handleChange = (event: typesHandleChange) => {
        const { name, value } = event.target;
        
        setformState(prevState => ({
        ...prevState,
        [name]: value,
        }));
    }

    

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(updateInfosEntreprise(formState))
        setopenNotification(true)
    }
    
    const handleCloseNotification = () => {
        setopenNotification(false)
    }

   
    useEffect(() => {
      document.title = "Settings - Entreprise";
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
      // Filter out 'profilePicture' and 'avatarUrl'
      const keysForm = Object.keys(entreprise).filter(key => key !== "avatarUrl");
    
      // Check if any of the other keys have been updated
      const isUpdated = keysForm.some(key => entreprise[key] !== formState[key]);
    
      // Update state if any key (except profilePicture and avatarUrl) is updated
      setDataIsUpdated(isUpdated);
    }, [formState]);

    
  
    
    
  return (
    <div className={ styles.main }>
            <Menu/>
            <Header/>
            <div className={styles.content}>
                <HeaderEntreprise dataIsUpdated={dataIsUpdated} title='Workspace Settings' handleSubmit={handleSubmit} setformState={setformState as any}/>
                <div  className={styles.avatarPersonalAccountCard}>
                  <p className={styles.avatarPersonalAccountCard_title}>Workspace Logo</p>
                  {/* <p className={styles.avatarPersonalAccountCard_text}>Put your brand front and center in Defendis by adding your logo.</p> */}
                  <AvatarUploadEntreprise/>
                </div>
                <div  style={{marginTop: 24}} className={styles.avatarPersonalAccountCard}>
                  <p className={styles.avatarPersonalAccountCard_title}>Workspace Information</p>
                  {/* <p className={styles.avatarPersonalAccountCard_text}>The information in this section is used for invoicing. Keep it up to date with the latest information available to you.</p> */}
                  <form onSubmit={handleSubmit}>
                    <FormControl component="fieldset" fullWidth>
                    <div style={{display: 'grid', gap: 16, marginBottom: 12}}>
                      <div className={styles.personalInfosInputs}>
                        <TextField InputProps={{sx: { fontSize: 14 }}}
                          value={formState.entrepriseName}
                          name='entrepriseName'
                          id="standard-basic"
                          label="Entreprise name"
                          variant="standard" 
                          onChange={handleChange}

                          disabled
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#ffffff",
                            },
                            "& .MuiInputLabel-root.Mui-disabled" :{
                              WebkitTextFillColor: "#aaaaaa",
                            }
                          }}
                        />
                        <TextField InputProps={{sx: { fontSize: 14 }}}
                          value={formState.ein}
                          onChange={handleChange}
                          name='ein'
                          id="standard-basic"
                          label="Entreprise ID"
                          variant="standard"

                          disabled
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#ffffff",
                          },
                          "& .MuiInputLabel-root.Mui-disabled" :{
                            WebkitTextFillColor: "#aaaaaa",
                          }
                          }}
                        />
                      </div>
                      <div className={styles.personalInfosInputs}>
                        <TextField InputProps={{sx: { fontSize: 14 }}}
                          value={formState.type} 
                          onChange={handleChange}
                          name='adrress1'
                          id="standard-basic"
                          label="Type"
                          variant="standard"
                          style={{color: "#333333"}}

                          disabled
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#ffffff",
                            },
                            "& .MuiInputLabel-root.Mui-disabled" :{
                            WebkitTextFillColor: "#aaaaaa",
                            }
                          }}
                        />
                        <TextField InputProps={{sx: { fontSize: 14 }}}
                          value={formState.country}
                          onChange={handleChange}
                          name='country'
                          id="standard-basic"
                          label="Country"
                          variant="standard"

                          disabled
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#ffffff",
                            },
                            "& .MuiInputLabel-root.Mui-disabled" :{
                              WebkitTextFillColor: "#aaaaaa",
                            }
                          }}
                        />
                      </div>
                    </div>
                    
                    </FormControl>
                    </form>
                </div>
                {/* <div style={{marginTop: 15}} className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Subscription history</p>
                    <p className={styles.avatarPersonalAccountCard_text}>View your current and expired subscriptions to Defendis</p>
                    <div className={styles.preferenceAccountInfo}>
                    <table className={styles.table}>
                      <thead>
                        <tr className={styles.tableHead}>
                          <th style={{paddingLeft: 8}}>Subscription</th>
                          <th style={{paddingLeft: 8}}>Start date</th>
                          <th style={{paddingLeft: 8}}>End date</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                            <tr className={styles.tableActivityRowWatchList}>
                          <td className={styles.tableCell}>{entreprise?.profileLicence}</td>
                          <td className={styles.tableCell} >
                            <div style={{display:'flex', alignItems: 'center', gap:10}}>
                            <FontAwesomeIcon icon={faCalendar} size='1x'/>
                            <p>April 22, 2024</p>
                            </div>
                          </td>
                          <td className={styles.tableCell} >
                            <div style={{display:'flex', alignItems: 'center', gap:10}}>
                            <FontAwesomeIcon icon={faCalendar} size='1x'/>
                            <p>April 22, 2024</p>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>      
                </div> */}
            </div>
            <ErrorNotification
                severity={'success'}
                open={openNotification}
                message={'Data updated successfuly'}
                handleClose={handleCloseNotification}
            />
        </div>
  )
}

export default EntrepriseSettings