import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { createStyles, makeStyles } from '@mui/styles';
import ReactGA from 'react-ga4';
import { Theme } from '@emotion/react';
import { authSelector } from '../../core/reducers/authReducer';
import { setNotificationAction, updatePasswordRecoverRequest } from '../../core/actions/authActions';
import ErrorNotification from '../settings/common/errorNotification';
import styles from './auth.module.scss';
import Logo from '../../icons/main/identity/defendisWhite.svg';
import AuthFooter from './components/authFooter';

// Types and Interfaces
interface AuthState {
  error: string;
  errorCode: number | null;
  passwordIsUpdated: boolean;
}

interface UrlValidationResult {
  isValid: boolean;
  token?: string;
  error?: {
    code: string;
    message: string;
  };
  type?: string;
}

interface PasswordValidation {
  hasMinLength: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
  matches: boolean;
}

interface PasswordErrors {
  password: string;
  rePassword: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidPassword: {
      borderBottom: '1px solid red !important',
    },
    validPassword: {
      borderBottom: '1px solid green !important',
    },
  })
);

// URL validation utility
const validateUrl = (hash: string, token: string | null): UrlValidationResult => {
  const params = new URLSearchParams(hash.substring(1));
  const error = params.get('error');
  const error_code = params.get('error_code');
  const error_description = params.get('error_description');

  if (error) {
    return {
      isValid: false,
      error: {
        code: error_code || 'unknown_error',
        message: decodeURIComponent(error_description || 'An unknown error occurred')
      }
    };
  }

  if (!token) {
    return {
      isValid: false,
      error: {
        code: 'missing_token',
        message: 'The link is invalid or has expired'
      }
    };
  }

  return {
    isValid: true,
    token,
    type: params.get('type') || 'recovery'
  };
};

const validatePassword = (password: string): PasswordValidation => {
  return {
    hasMinLength: password.length >= 8,
    hasUpperCase: /[A-Z]/.test(password),
    hasLowerCase: /[a-z]/.test(password),
    hasNumber: /[0-9]/.test(password),
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    matches: false
  };
};

const getPasswordStrengthMessage = (validation: PasswordValidation): string => {
  if (!validation.hasMinLength) return 'Password must be at least 8 characters';
  if (!validation.hasUpperCase) return 'Password must contain an uppercase letter';
  if (!validation.hasLowerCase) return 'Password must contain a lowercase letter';
  if (!validation.hasNumber) return 'Password must contain a number';
  if (!validation.hasSpecialChar) return 'Password must contain a special character';
  return '';
};

const FirstTimeSetPassword: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector) as AuthState;
  const classes = useStyles();
  const [paramsToken] = useSearchParams();

  // Form state
  const [password, setPassword] = useState<string>('');
  const [rePassword, setRePassword] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [urlValidation, setUrlValidation] = useState<UrlValidationResult>({ isValid: true });
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>({
    hasMinLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    matches: false
  });
  const [passwordErrors, setPasswordErrors] = useState<PasswordErrors>({
    password: '',
    rePassword: ''
  });

  // Validate URL on mount
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    document.title = 'New Password';
    const validationResult = validateUrl(location.hash, paramsToken.get('token'));
    setUrlValidation(validationResult);
  }, [location.hash, paramsToken]);

  // Handle password updates
  useEffect(() => {
    if (loading && (auth.passwordIsUpdated || auth.error !== '')) {
      setOpenNotification(true);
      setLoading(false);

      if (auth.passwordIsUpdated) {
        setTimeout(() => {
          navigate(urlValidation.type === 'recovery' ? '/' : '/userInvited');
        }, 1500);
      }
    }
  }, [auth.error, auth.passwordIsUpdated, loading, navigate, urlValidation.type]);

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setPassword(value);
    
    const validation = validatePassword(value);
    validation.matches = value === rePassword;
    setPasswordValidation(validation);
    
    const strengthMessage = getPasswordStrengthMessage(validation);
    setPasswordErrors({
      password: strengthMessage,
      rePassword: value !== rePassword && rePassword !== '' ? 'Passwords do not match' : ''
    });
    
    setError(!validation.matches || !!strengthMessage);
  };

  const handleChangeRePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setRePassword(value);
    
    const validation = validatePassword(password);
    validation.matches = password === value;
    setPasswordValidation(validation);
    
    setPasswordErrors(prev => ({
      ...prev,
      rePassword: password !== value ? 'Passwords do not match' : ''
    }));
    
    setError(!validation.matches || !!getPasswordStrengthMessage(validation));
  };

  const submit = () => {
    if (!error && urlValidation.token && password) {
      setLoading(true);
      dispatch(updatePasswordRecoverRequest({
        jwt: urlValidation.token,
        password
      }));

      setTimeout(() => {
        dispatch(setNotificationAction({
          success: true,
          error: '',
          errorCode: null
        }));
        setLoading(false);
        setOpenNotification(false);
      }, 3500);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  const isSubmitDisabled = loading || 
    error || 
    !password || 
    !rePassword || 
    !passwordValidation.matches ||
    !passwordValidation.hasMinLength ||
    !passwordValidation.hasUpperCase ||
    !passwordValidation.hasLowerCase ||
    !passwordValidation.hasNumber ||
    !passwordValidation.hasSpecialChar;

  // Show error state if URL is invalid
  if (!urlValidation.isValid) {
    return (
      <div style={{
        backgroundColor: '#141414',
        width: '100vw',
        height: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div style={{ position: 'absolute', top: 0, left: 0, padding: '6px 0px' }}>
          <img src={Logo} className={styles.logo} alt="Defendis Logo" />
        </div>

        <div style={{
          textAlign: 'center',
          padding: '2rem',
          maxWidth: '400px',
          backgroundColor: '#1F1F1F',
          borderRadius: '8px',
          margin: '0 1rem'
        }}>
          <Typography
            variant="h5"
            style={{
              color: '#E53935',
              marginBottom: '1rem'
            }}
          >
            Link Expired
          </Typography>
          <Typography
            style={{
              color: '#fff',
              marginBottom: '1rem'
            }}
          >
            The password reset link has expired or is invalid. Please request a new password reset link.
          </Typography>
          <Button
            onClick={() => navigate('/forgotPassword')}
            className={styles.btnLogin}
            style={{
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            Request New Link
          </Button>
          <Button
            onClick={() => navigate('/login')}
            className={styles.btnLogin}
            style={{
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            Return to Login
          </Button>
        </div>

        <div style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          padding: '1rem'
        }}>
          <AuthFooter />
        </div>
      </div>
    );
  }

  // Normal password set view
  return (
    <div style={{ backgroundColor: '#141414', width: '100vw', height: '100vh', position: 'relative' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, padding: '6px 0px', cursor: 'pointer' }} onClick={() => navigate('/')}>
        <img src={Logo} className={styles.logo} alt="Defendis Logo" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '95vh' }}>
        <div className={styles.login} style={{ width: 'auto' }}>
          <div style={{ paddingTop: '3rem', padding: '4rem' }}>
            <form onSubmit={e => e.preventDefault()}>
              <div className={styles.loginInputs}>
                <span className={styles.titleLogin}>New password</span>
                <TextField
                  type='password'
                  onChange={handleChangePassword}
                  id="standard-basic"
                  label="Password"
                  variant="standard"
                  fullWidth
                  error={!!passwordErrors.password}
                  helperText={passwordErrors.password}
                  InputProps={{
                    sx: { fontSize: 14 },
                    endAdornment: password !== '' && (
                      <FontAwesomeIcon 
                        style={{ marginRight: 10 }} 
                        icon={error ? faCircleXmark : faCircleCheck} 
                        color={error ? '#E11900' : '#20B444'} 
                      />
                    )
                  }}
                />
                <TextField
                  type='password'
                  onChange={handleChangeRePassword}
                  id="standard-basic"
                  label="Re-type Password"
                  variant="standard"
                  fullWidth
                  error={!!passwordErrors.rePassword}
                  helperText={passwordErrors.rePassword}
                  InputProps={{
                    sx: { fontSize: 14 },
                    endAdornment: rePassword !== '' && (
                      <FontAwesomeIcon 
                        style={{ marginRight: 10 }} 
                        icon={error ? faCircleXmark : faCircleCheck} 
                        color={error ? '#E11900' : '#20B444'} 
                      />
                    )
                  }}
                />
                <Button
                  onClick={submit}
                  type='submit'
                  style={{ 
                    marginTop: 48, 
                    fontSize: 14, 
                    fontWeight: 500,
                    opacity: isSubmitDisabled ? 0.7 : 1 
                  }}
                  disabled={isSubmitDisabled}
                >
                  {loading ? 'Setting Password...' : 'Set Password'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', gap: 16, justifyContent: 'center', alignItems: 'center' }}>
        <AuthFooter />
      </div>
      {openNotification && auth.error !== "" && auth.errorCode && (
        <ErrorNotification
          severity={auth.errorCode !== 200 ? 'error' : 'success'}
          open={openNotification}
          message={auth.error}
          handleClose={handleCloseNotification}
        />
      )}
    </div>
  );
};

export default FirstTimeSetPassword;