import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import styles from '../../settings.module.scss';
import { removeUserPictureAPI, uploadUserPictureProfileAPI } from '../../../../core/api/userApi';
import { useDispatch } from 'react-redux';
import { setPictureProfileAction } from '../../../../core/actions/authActions';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../../core/reducers/authReducer';
import { getUserInfoRequest, setMessageAction } from '../../../../core/actions/userActions';
import { userSelector } from '../../../../core/reducers/userReducer';
import emptyUser from "../../../../icons/main/identity/defaultUser/emptyUser.jpg"
import { env } from '../../../../environement/env';
import { CircularProgress, Alert, Snackbar } from '@mui/material'
import { faCalendar, faChevronDown, faChevronUp, faCircleCheck, faDownload, faSearch, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Input = styled('input')({
  display: 'none',
});

const AvatarUpload: React.FC = () => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector(userSelector)
  const [avatarSrc, setAvatarSrc] = useState<string>(userInfo.profilePicture);
  const [isUploaded, setisUploaded] = useState(false)
  const handleOpenNotf = () => setisUploaded(!isUploaded)

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const img: File = event.target.files[0];
      setAvatarSrc(URL.createObjectURL(img));
      const formData: FormData = new FormData()
      formData.append('profilePicture', img);
      uploadAvatarFile(formData)
      setTimeout(() => {
        dispatch(getUserInfoRequest())
      }, 3000);
    }
  };

  const handleRemoveAvatar = async () => {
    const dataSrc = await removeUserPictureAPI()
    setAvatarSrc(dataSrc)
    setTimeout(() => {
      dispatch(getUserInfoRequest())
    }, 500);
  };

  const uploadAvatarFile = async (formData: FormData, isRemoval = false) => {

    const response = await uploadUserPictureProfileAPI(formData);
    if (!isRemoval) {
      dispatch(setPictureProfileAction(response || ""));
      dispatch(getUserInfoRequest())
    } else {
      dispatch(setPictureProfileAction(""));  // Set to an empty string or appropriate default on removal
    }
  }

  return (
    <div className={styles.avatarUpload}>
      <Avatar src={avatarSrc || emptyUser} sx={{ width: '56px', height: '56px' }} />
      {
         avatarSrc && !avatarSrc.includes('emptyU.jpg') ? (
          <div style={{ marginTop: 0, display: 'grid', alignContent: 'center', justifyContent: 'center' }}>
            <label htmlFor="avatar-upload-input" >
              <Input accept="image/*" id="avatar-upload-input" type="file" onChange={handleAvatarChange} />
              <p className={styles.uploadAvatarBtn} style={{ paddingBottom: 4 }}>Change avatar</p>
            </label>
            <div className={styles.uploadAndRemoveAvatar}>
              <p className={styles.removeAvatar} role="button" onClick={handleRemoveAvatar}>Remove</p>
            </div>
          </div>
        ) : (
          <label htmlFor="avatar-upload-input" className={styles.uploadAndRemoveAvatar}>
            <Input accept="image/*" id="avatar-upload-input" type="file" onChange={handleAvatarChange} />
            <p style={{ cursor: 'pointer', paddingTop: 18 }} className={styles.uploadAvatarBtn} role="button">
              Upload Avatar
            </p>
          </label>
        )
      }


    </div>
  );
};

export default AvatarUpload;