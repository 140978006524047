import React from 'react'
import styles from '../../../modules.module.scss'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../../core/selectors/ransomeSelector'
import { CircularProgress } from '@mui/material'
import EmptyStateCerts from '../../../common/EmptyStateCerts'


function formatNumber(number: number) {
  return new Intl.NumberFormat('en-US').format(number);
}

function Overview() {
  const { isLoadingCountryDetail, countryDetail } = useSelector(ransomeSelector)
  const { victims, isLoadingVictims } = useSelector(ransomeSelector)
 
 
  return (
    <>
    {
      !isLoadingVictims? 
      countryDetail?.certCountry?.length!== 0 ?

      <table className={styles.table}style={{marginTop:25}}>
        <tbody>
            
            <tr className={styles.tableActivityRowRansomWatchList}>
              <td style={{width: '150px',padding: '16px', color: "white"}} className={styles.tableCell}>
              Capital
              </td>
                  <td className={styles.tableCell}>
                  {countryDetail?.countryDetail?.[0]?.capital}
                  </td>
            </tr>
            <tr className={styles.tableActivityRowRansomWatchList}>
              <td style={{padding: '16px', color: "white"}} className={styles.tableCell}>
              Population
              </td>
                  <td className={styles.tableCell}>
                  {formatNumber(Number(countryDetail?.countryDetail?.[0]?.population))} people
                  </td>
            </tr>
            <tr className={styles.tableActivityRowRansomWatchList}>
              <td style={{padding: '16px', color: "white"}} className={styles.tableCell}>
              Area
              </td>
                  <td className={styles.tableCell}>
                  {formatNumber(Number(countryDetail?.countryDetail?.[0]?.area_sq_km))} square kilometers
                  </td>
            </tr>
            <tr className={styles.tableActivityRowRansomWatchList}>
              <td style={{padding: '16px', color: "white"}} className={styles.tableCell}>
              Timezone
              </td>
                  <td className={styles.tableCell}>
                  {countryDetail?.countryDetail?.[0]?.timezones?.replace('}','')?.replace('{','')}
                  </td>
            </tr>
          </tbody>
      </table>
      : 
      <div></div>
      :
      <div className={styles.loaderVul}><CircularProgress /></div>
    } 
    </>
  )
}

export default Overview
