import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Analytics } from '@june-so/analytics-node'
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import styles from '../modules.module.scss';
import MenuDropDownHeader from '../../modules/common/MenuDropDownHeader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  SUCCESS_NOTIFICATIONS,
  REQUEST_DARK,
  REQUEST_DOMAINE,
  REQUEST_SURFACEWEB,
  GET_ALL_VULNERABILITIES,
  REQUEST_RANSOME,
  REQUEST_CREDITS_CARD,
  ADD_NOTIFICATION_USER,
  UPDATE_NOTIFICATION_USER,
  SUCCESS_NOTIFICATIONS_USER,
  REQUEST_CREADENTIALS_LEAK,
  UPDATE_LOGIN_ENTREPRISE,
  REQUEST_GET_ALL_ENTREPRISES,
} from '../../../core/constants';
import { notificationSelector } from '../../../core/selectors/notificationBellSelector';
import { supabaseClient } from '../../../core/supabase';
import { cvesFromWatchListAPI } from '../../../core/api/vulnerabilityApi';
import { ransomeSelector } from '../../../core/selectors/ransomeSelector';
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector';
import { threatIntelSelector } from '../../../core/selectors/threatIntelSelector';
import { PageSort } from '../../../core/api/threatIntelApi';
import { typesHandleChange } from '../../settings/common/constants';
import { MenuItem, Select } from '@mui/material';
import { useIntercom } from '../../main/intercom/IntercomSetup'
import { entrepriseSelector } from '../../../core/reducers/entrepriseReducer';

enum EntrepriseTypeSupaBase {
  Startup = 'Startup',
  Government = 'Government',
  Entreprise = 'Entreprise',
  Bank = 'Bank',
  Partner = 'Partner',
  Education = 'Education'
}

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifs, notifsUser } = useSelector(notificationSelector);
  const { domains, surfaceWeb } = useSelector(brandIntelSelector)
  const { creditsCard, credentialsLeak } = useSelector(threatIntelSelector)
  const entreprise = useSelector(entrepriseSelector);
  const { entreprises } = entreprise
  const [entId, setentId] = useState("")

  // console.log('entId:', entId);
  // console.log('Entreprises:', entreprises);

  useEffect(() => {
    const storedEntId = localStorage.getItem("loginEntreprise");
    if (storedEntId) {
      setentId(storedEntId);
    } else if (entreprises?.length > 0) {
      // Default to the first entreprise if none is stored
      setentId(entreprises[0].id.toString());
      localStorage.setItem("loginEntreprise", entreprises[0].id.toString());
    }
  }, [entreprises]);



  const emailStorageVa = window.localStorage.getItem('email')
  const hmacValue = window.localStorage.getItem('hmac')
  const fullNamed = (window.localStorage.getItem("fullName") || "") + " " + (window.localStorage.getItem("name") || "");
  const profilePicture = window.localStorage.getItem("avatar")

  useIntercom('isows87y', {
    app_id: 'isows87y',
    api_base: 'https://api-iam.intercom.io',
    email: emailStorageVa as string,
    user_hash: hmacValue as string,
    name: fullNamed,
    avatar: profilePicture,
  })




  const filterByWatchList = async (data: any[]) => {
    // Map over data and return cve_id or null for non-matching items
    const cveIdsWithNull = data.map(item => {
      return item.isVul ? item.data.cve_id : null; // Explicitly return null if not isVul
    });

    // Filter out null and undefined values
    const cveIds = cveIdsWithNull.filter(item => item !== null && item !== undefined);
    let response: any[] = []
    if (cveIds.length > 0)
      response = await cvesFromWatchListAPI(cveIds)



    const resultwithNull = data.filter(item =>
      !item.isVul ? item : response.some((respItem: any) => respItem.cve_id === item.data.cve_id) ? item : null
    );
    const result = resultwithNull.filter(item => item !== null && item !== undefined);
    return result
  };
  const filterDomains = (data: any[]) => {
    const resultsDomainWithNull = data?.filter((item: any) =>
      item?.isDomains && domains?.some(dmn => dmn?.id === item?.data?.id)
        ? item : item
    )

    const resultsDomains = resultsDomainWithNull.filter(item => item !== null && item !== undefined);
    return resultsDomains
  }
  const filterSurfaceWeb = (data: any[]) => {
    const resultsSurfaceWebWithNull = data?.filter((item: any) =>
      item?.isSurfaceWeb && surfaceWeb?.some(surface => surface?.id === item?.data?.id)
        ? item : item
    )

    const resultssurfaceWeb = resultsSurfaceWebWithNull.filter(item => item !== null && item !== undefined);
    return resultssurfaceWeb
  }
  const filterCreditsCard = (data: any[]) => {
    const resultsCreditsCardWebWithNull = data?.filter((item: any) =>
      item?.isCreditsCard && creditsCard?.data?.some(crd => crd?.cardid === item?.data?.cardid)
        ? item : item
    )

    const resultscreditsCard = resultsCreditsCardWebWithNull.filter(item => item !== null && item !== undefined);
    return resultscreditsCard
  }
  const filterPassword = (data: any[]) => {
    const resultsPasswordsWithNull = data?.filter((item: any) =>
      item?.isCredentials && credentialsLeak?.data?.some(crd => crd?.passwordid === item?.data?.passwordid)
        ? item : item
    )

    const resultsPasswords = resultsPasswordsWithNull.filter(item => item !== null && item !== undefined);
    return resultsPasswords
  }
  const filterAndUpdateNotUser = (dataNot: any[], dataNotUser: any[]) => {
    const result = dataNot.filter(async (item: any) => {

      const exist = dataNotUser.some((it: any) => item.id === it.notifId)

      if (!exist) {
        await supabaseClient.from('NotificationUser').insert({
          userId: window.localStorage.getItem('userid'),
          notifId: item.id,
          read: false
        })
      }
      return item
    })
    return result
  }
  const handleChangeEntId = async (event: typesHandleChange) => {
    setentId(event.target.value)
    dispatch({
      type: UPDATE_LOGIN_ENTREPRISE,
      payload: event.target.value
    })
    setTimeout(() => {
      window.location.reload()
    }, 1000);
  }

  // useEffect(() => {
  //   // Fetch existing notifications
  //   const fetchNotifications = async () => {
  //     const { data, error } = await supabaseClient
  //       .from('Notification')
  //       .select('*')
  //       .order('created_at', { ascending: false });
  //       const userId = window.localStorage.getItem('userid') || ''
  //     const notUser = await supabaseClient.from("NotificationUser").select("*").eq("userId", userId)
  //     if (error || notUser.error) {
  //       console.error('Error fetching notifications:', error);
  //     } else {
  //       const resultCVES = await filterByWatchList(data)
  //       const resultsDomains = filterDomains(resultCVES)
  //       const resultsSurfaceWeb = filterSurfaceWeb(resultsDomains)
  //       const resultCreditsCard = filterCreditsCard(resultsSurfaceWeb)
  //       const resultPassword = filterPassword(resultCreditsCard)
  //       const notUsers = filterAndUpdateNotUser(resultPassword, notUser.data)

  //       dispatch({type: SUCCESS_NOTIFICATIONS_USER, payload: notUser.data})
  //      dispatch({ type: SUCCESS_NOTIFICATIONS, payload: notUsers });
  //     }
  //   };

  //   fetchNotifications();

  //   // Subscribe to real-time updates
  //   const channel = supabaseClient
  //     .channel('notifications-channel')
  //     .on(
  //       'postgres_changes',
  //       { event: '*', schema: 'public', table: 'Notification' },
  //       async (payload) => {
  //         // console.log('Real-time event received:', payload);
  //         const dataCred: PageSort = {
  //           page:1,
  //           sortBy: null
  //       }

  //         switch (payload.eventType) {

  //           case 'INSERT':
  //             dispatch({
  //               type: ADD_NOTIFICATION,
  //               payload: payload.new,
  //             });

  //             dispatch({
  //               type: REQUEST_DARK,
  //               payload: 'all'
  //           })
  //           dispatch({
  //               type: REQUEST_DOMAINE,
  //               payload: 'all'
  //           })
  //           dispatch({ 
  //               type: REQUEST_SURFACEWEB,
  //               payload: 'all'
  //           });
  //           dispatch({
  //               type: GET_ALL_VULNERABILITIES,
  //               payload: "all"
  //              })
  //              dispatch({
  //               type: REQUEST_RANSOME,
  //               payload: 'all'
  //             })
  //             dispatch({ type: REQUEST_CREDITS_CARD, payload: dataCred })
  //             dispatch({ type: REQUEST_CREADENTIALS_LEAK, payload: dataCred}),
  //             fetchNotifications();
  //             break;

  //           case 'UPDATE':
  //             dispatch({
  //               type: UPDATE_NOTIFICATION,
  //               payload: payload.new,
  //             });
  //             dispatch({type: UPDATE_NOTIFICATION_USER, payload: payload.new})
  //             break;

  //           case 'DELETE':
  //             dispatch({
  //               type: DELETE_NOTIFICATION,
  //               payload: payload.old,
  //             });
  //             break;

  //           default:
  //             console.warn('Unhandled event type:', payload);
  //         }
  //       }
  //     )
  //     .subscribe();
  //     dispatch({
  //       type: REQUEST_GET_ALL_ENTREPRISES
  //   })
  //   const loginEntrprise = localStorage.getItem("loginEntreprise")
  //   if(loginEntrprise) {
  //       setentId(loginEntrprise)
  //   }
  //   // Cleanup on unmount
  //   return () => {
  //     supabaseClient.removeChannel(channel);
  //   };
  // }, [dispatch]);

  const notificationsUnread = notifs.filter((item) => notifsUser.some((it: any) => item.id === it.notifId && it.read === false));
  // console.log('Notifications:', notifs);

  return (
    <div className={styles.header}>

      <div>
        {entreprises?.length > 1 && (
          <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleChangeEntId} value={entId} IconComponent={() => entreprises?.length > 0 ? (<FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginLeft: '-20px' }} />) : null} style={{ backgroundColor: "transparent" }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: '#1f1f1f',
                  borderRadius: '8px',
                  padding: '0px',
                  boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                  maxHeight: '360px',
                  overflowY: 'auto',
                },
              },
              MenuListProps: {
                dense: false,
                disablePadding: true,
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}

          >
            {
              entreprises?.slice().sort((a: { entrepriseName: string }, b: { entrepriseName: string }) => a.entrepriseName.localeCompare(b.entrepriseName)).map((ent: { id: string | number; entrepriseName: string }) =>
              (
                <MenuItem key={ent.id} value={ent.id.toString()} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, margin: '6px 0px 6px 6px', padding: '8px 54px 8px 16px' }}>{ent.entrepriseName.substring(0, 30)}</MenuItem>)
              )
            }
          </Select>
        )}
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>

          <div
            className={styles.notification}
            onClick={() => {
              navigate('/notifications');
            }}
          >
            <div
              style={{ cursor: 'pointer', height: 40, width: 40 }}
              className={styles.iconHover}
            >
              <FontAwesomeIcon icon={faBell} />
            </div>
            {notificationsUnread && notificationsUnread.length > 0 && (
              <span className={styles.numberNotifications}>
                {notificationsUnread.length > 9 ? '+9' : notificationsUnread.length}
              </span>
            )}
          </div>
        </div>
        <MenuDropDownHeader />
      </div>
    </div>
  );
}

export default Header;
