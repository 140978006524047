import { useEffect, useState } from "react";
import { checkIfTokenExpiredApi } from "./core/api/authApi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, batch } from "react-redux"; // Import batch
import { authSelector } from "./core/reducers/authReducer";
import { AxiosError } from "axios";
import {
  getInfoSecurityPageRequest,
  getUserInfoRequest,
} from "./core/actions/userActions";
import {
  getInfosEntrepriseRequest,
  getUsersEntrepriseRequest,
} from "./core/actions/entrepriseActions";
import {
  GET_ALL_VULNERABILITIES,
  GET_ROLE_USER,
  Page,
  REQUEST_CREADENTIALS_LEAK,
  REQUEST_CREDITS_CARD,
  REQUEST_DARK,
  REQUEST_DATABASELEAK,
  REQUEST_DOCUMENTS_LEAK,
  REQUEST_DOMAINE,
  REQUEST_GET_ALL_ENTREPRISES,
  REQUEST_HOME_STATS,
  REQUEST_NEGOCIATIONS,
  REQUEST_RANSOME,
  REQUEST_RANSOME_COUNTRIES,
  REQUEST_RANSOME_OVERVIEW,
  REQUEST_ROLES,
  REQUEST_SOCIALMEDIA,
  REQUEST_SURFACEWEB,
  REQUEST_TOTAL_RECORDS_CREDENTIALS,
  REQUEST_VICTIMS,
  REQUEST_VULNERABILITIES_OVERVIEW,
  SortVul,
} from "./core/constants";
import { statsSelector } from "./core/selectors/statsSelector";
import { CircularProgress } from "@mui/material";
import styles from "./views/settings/settings.module.scss";
import Login from "./views/auth/login";
import { PageSort } from "./core/api/threatIntelApi";

interface Props {
  children: React.ReactNode;
}

export function TokenVerifier({ children }: Props) {
  const [initialLoading, setInitialLoading] = useState(true);
  const auth = useSelector(authSelector)
  const {isLoadingHome} = useSelector(statsSelector)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = window.localStorage.getItem("jwt");

  const fetchData = async () => {
    const dataSortVul: SortVul = {
      sortByDate: "all",
      sortBySeverity: "all",
      sortById: "all",
      search:'',
      page:1
    };
    const dataCred: PageSort = {
      page: 1,
      sortBy: null,
    };

    batch(() => {
      // Remove duplicate dispatch
      dispatch({ type: GET_ROLE_USER });
      dispatch({ type: REQUEST_ROLES });
      dispatch(getInfoSecurityPageRequest());
      dispatch(getUsersEntrepriseRequest());
      dispatch(getUserInfoRequest());
      dispatch(getInfosEntrepriseRequest());
      // Group actions with 'all' payload
      const actionsWithAllPayload = [
          REQUEST_HOME_STATS,
        // REQUEST_DARK,
        // REQUEST_DOMAINE,
        // REQUEST_SURFACEWEB,
        // REQUEST_SOCIALMEDIA,
        // REQUEST_VULNERABILITIES_OVERVIEW,
        // REQUEST_RANSOME,
        // REQUEST_VICTIMS,
        // REQUEST_RANSOME_OVERVIEW,
        // REQUEST_RANSOME_COUNTRIES,
      ];

      actionsWithAllPayload.forEach((actionType) => {
        dispatch({ type: actionType, payload: "all" });
      });

      // Group credential-related actions
      // const dataCredActions = [
      //   REQUEST_CREADENTIALS_LEAK,
      //   REQUEST_DOCUMENTS_LEAK,
      //   REQUEST_CREDITS_CARD,
      // ];

      // dataCredActions.forEach((actionType) => {
      //   dispatch({ type: actionType, payload: dataCred });
      // });

      // Other individual dispatches
      // dispatch({
      //   type: GET_ALL_VULNERABILITIES,
      //   payload: dataSortVul,
      // });
      // dispatch({ type: REQUEST_NEGOCIATIONS });
      
    });

    setInitialLoading(false);
  };

  const getAndVerifyToken = async () => {
    if (!token) {
      navigate("/login");
    } else if (auth.authenticated && !auth.loginEntreprise) {
      navigate("/login");
    } else {
      try {
        const response = await checkIfTokenExpiredApi(token);
        if (response.authentificated) {
          fetchData();
        } else {
          navigate("/login");
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                // console.log("Caught error:", error);
                // console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
      }
    }
  };

  useEffect(() => {
    getAndVerifyToken();
  }, [auth]);

  if (!token) {
    return <Login />;
  }

  if (initialLoading || isLoadingHome) {
    return (
      <div className={styles.main} style={{backgroundColor: '#141414'}}>
        <div className={styles.loader}>
          <CircularProgress />
        </div>
        <p className={styles.loaderTitle} style={{fontSize: 14}}>
          Almost there, we are loading your data...
        </p>
      </div>
    );
  }

  return <>{children}</>;
}

export default TokenVerifier;