import { faCalendar, faChevronDown, faChevronUp, faCircleCheck, faDownload, faSearch, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../modules.module.scss';
import ReactGA from 'react-ga4';
import { useSelector, useDispatch } from 'react-redux';
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector';
import { Negociations, REQUEST_NEGOCIATIONS } from '../../../../core/constants';
import { setMessageChatAction } from '../../../../core/actions/ransomeActions';
import { CircularProgress } from '@mui/material';
import EmptyStateGroups from '../../common/EmptyStateGroups'
import EmptyStateNegociations from '../../common/EmptyStateNegociations';


type SortOrder = 'asc' | 'desc';

function NegotiationsSection() {
    const navigate = useNavigate()
    const { groupDetail, negociations, isLoadingGroups } = useSelector(ransomeSelector)
    const [search, setSearch] = useState<string>('')
    const [sortBy, setSortBy] = useState<string>('name_group')
    const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
    const dispatch = useDispatch()

    const filteredChats = groupDetail && negociations
        ? negociations.filter(negociations => negociations.name_group === groupDetail.name)
        : [];


    useEffect(() => {
        document.title = 'Ransomware Negotiations'
        ReactGA.send({ hitType: 'Ransomware Negotiations', page: window.location.pathname });
        if(!negociations) {
            dispatch({ type: REQUEST_NEGOCIATIONS })
        }
    }, []);

    const resultSearch = search !== ''
        ? negociations?.filter(item => item.name_group.toLowerCase().includes(search.toLowerCase()))
        : negociations;

    const handleSort = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
    };

    const sortedNegotiations = resultSearch?.sort((a, b) => {
        let comparison = 0;
        if (sortBy === 'name_group') {
            comparison = a.name_group.localeCompare(b.name_group);
        } else if (sortBy === 'initial_ransom') {
            comparison = a.initial_ransom.localeCompare(b.initial_ransom);
        } else if (sortBy === 'negotiated_ransom') {
            comparison = a.negotiated_ransom.localeCompare(b.negotiated_ransom)
        } else if (sortBy === 'paid') {
            comparison = a.paid === b.paid ? 0 : a.paid ? 1 : -1;
        } else if (sortBy === 'messages') {
            comparison = a?.message_data?.length - b.message_data?.length
        } else if (sortBy === 'ransome') {
            comparison = a.name_group.localeCompare(b.name_group)
        }

        return sortOrder === 'asc' ? comparison : -comparison;
    });

    const setMessageDetail = (item: Negociations) => {
        dispatch(setMessageChatAction(item));
        navigate('/ransomwareIntel/conversations');
    };

    return (
        <>
            {
                !isLoadingGroups ?
                    groupDetail?.slug?.length ? (
                        <table className={styles.table}>
                            <thead>
                                <tr className={styles.tableHead}>
                                    <th style={{ paddingLeft: 8, cursor: 'pointer' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            <span>Country</span>
                                        </div>
                                    </th>
                                    <th style={{ paddingLeft: 16, cursor: 'pointer' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            <span>Victims</span>
                                        </div>
                                    </th>
                                    <th style={{ paddingLeft: 8, cursor: 'pointer' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            <span>Discovery Date</span>
                                        </div>
                                    </th>
                                    <th style={{ paddingLeft: 8, cursor: 'pointer' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            <span>Attack Date</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredChats.map((item) => (
                                    <tr key={item.chat_id} className={styles.tableActivityRow}>
                                        <td style={{ padding: '16px' }} className={styles.tableCell}>
                                            {item?.name_group}
                                        </td>
                                        <td style={{ padding: '16px' }} className={styles.tableCell}>
                                            {item?.initial_ransom}
                                        </td>
                                        <td style={{ padding: '16px' }} className={styles.tableCell}>
                                            {item?.negotiated_ransom}
                                        </td>
                                        {
                                            item?.paid ?
                                                <FontAwesomeIcon icon={faCircleCheck} width={23} height={24} color="#20B444" />
                                                :
                                                <div style={{ marginLeft: 5, height: 8, width: 8, borderRadius: "100%", border: "3px solid #383838", backgroundColor: "transparent" }} />
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    ) : (
                        <div className={styles.loaderVul}>
                            <EmptyStateNegociations />
                        </div>
                    )
                    : (
                        <div className={styles.loaderVul}><CircularProgress /></div>
                    )
            }
        </>
       

    );
}

export default NegotiationsSection;
