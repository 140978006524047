import React, { useEffect, useState } from 'react';
import Menu from './common/settingsMenu';
import Header from '../modules/common/Header';
import styles from './settings.module.scss';
import HeaderWatchList from './components/headers/headerWatchList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { watchListSelector } from '../../core/reducers/watchListReducer';
import { GET_WATCH_LIST } from '../../core/constants';
import ReactGA from 'react-ga4';
import { CircularProgress } from '@mui/material';

import BrandKeywordsWatchList from './components/watchlists/keywordsWatchList';
import AssetsWatchList from './components/watchlists/assetsWatchList';
import DomainsWatchList from './components/watchlists/domainsWatchList';
import BinsWatchList from './components/watchlists/binsWatchList';
import RansomGroupsWatchList from './components/watchlists/ransomWatchList';
import ExecutiveWatchList from './components/watchlists/executiveWatchList';
import IpWatchList from './components/watchlists/ipWatchList';

interface WatchListType {
  component: React.ComponentType<any>;
  title: string;
}

const watchListTypes: Record<string, WatchListType> = {
  assets: {
    component: AssetsWatchList,
    title: 'Assets Watchlist',
  },
  brandKeyword: {
    component: BrandKeywordsWatchList,
    title: 'Brand Keywords Watchlist',
  },
  domains: {
    component: DomainsWatchList,
    title: 'Domains Watchlist',
  },
  bins: {
    component: BinsWatchList,
    title: 'BINs Watchlist',
  },
  executives: {
    component: ExecutiveWatchList,
    title: 'Executives Watchlist',
  },
  ransomGroups: {
    component: RansomGroupsWatchList,
    title: 'Ransom Groups Watchlist',
  },
  ips: {
    component: IpWatchList,
    title: 'IPs Watchlist',
  },
};

function WatchList() {
    const dispatch = useDispatch();
    const { watchList, isLoading } = useSelector(watchListSelector);
    const [selectedType, setSelectedType] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        document.title = 'Watchlists Settings';
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
        dispatch({ type: GET_WATCH_LIST });
    }, [dispatch]);

    const handleTypeSelect = (type: string) => {
        setSelectedType(type);
    };

    const renderWatchList = () => {
      const { component: WatchListComponent } = watchListTypes[selectedType] || {}; 

      if (!WatchListComponent) {
        return null; 
      }

      return <WatchListComponent open={open} setOpen={setOpen} />;
    };

    return (
        <div className={styles.main}>
            <Menu />
            <Header />
            <div className={styles.content}>
                <HeaderWatchList
                    open={open}
                    setOpen={setOpen}
                    selectType={selectedType}
                    goBack={
                        selectedType === '' ? (
                            <p className={styles.personnalInfoHeadr_firstSection_settings}>Settings</p>
                        ) : (
                            <p onClick={() => setSelectedType('')} style={{ cursor: 'pointer', color: '#9d9d9d', fontSize: '13px', fontWeight: 500, display: 'flex', alignItems: 'center', gap: '7px' }}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                                Go Back
                            </p>
                        )
                    }
                    title={selectedType === '' ? 'Watchlists' : watchListTypes[selectedType]?.title || ''} 
                />

                {isLoading ? (
                    <div className={styles.loaderVul}>
                        <CircularProgress />
                    </div>
                ) : selectedType === '' ? (
                    <div className={styles.avatarPersonalAccountCard}>
                        <p className={styles.avatarPersonalAccountCard_title}>Active Watchlists</p>
                        <p className={styles.avatarPersonalAccountCard_text}>Watchlists are dynamic lists where you can add indicators you want to monitor closely.</p>

                        <table className={styles.table} style={{ marginTop: 25 }}>
                            <thead>
                                <tr className={styles.tableHead}>
                                    <th style={{ paddingLeft: 8 }}>Type</th>
                                    <th style={{ paddingLeft: 8, width: 90 }}>Items</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(watchListTypes).map(([type, { title }]) => (
                                    <tr className={styles.tableActivityRowRansomWatchList} key={type} style={{ height: 49 }}>
                                        <td onClick={() => handleTypeSelect(type)} style={{ cursor: 'pointer' }} className={styles.tableCell}>
                                            {title}
                                        </td>
                                        <td className={styles.tableCell}>{watchList[type]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    renderWatchList()
                )}
            </div>
        </div>
    );
}

export default WatchList;