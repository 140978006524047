import { faCalendar, faChevronDown, faChevronUp, faDownload, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import styles from '../../../modules.module.scss'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import ReactGA from 'react-ga4';
import { CircularProgress, Alert, Snackbar, MenuItem, Select } from '@mui/material'

import Pagination from '@mui/material/Pagination';
import { useTheme } from '@mui/material/styles';
import EmptyState from '../../../common/EmptyState'
import { getCredentialLeakExcelApi, PageSort } from '../../../../../core/api/threatIntelApi'
import { notificationSelector } from '../../../../../core/selectors/notificationBellSelector'
import { exportToExcel } from '../../../components/ExportToExcel'
import SearchField from '../../../components/searchField/SearchField'
import { useGetDataCredentials } from './hooks/useGetDataCredentials'

type SortOrder = 'asc' | 'desc';

interface DateRange {
  value: string;
  label: string;
  date: string | null;
}


export const renderColors = (riskScore: number) => {
  if (riskScore === 0.0)
    return {
      textColor: "#757575",
      backgroundColor: "#e6e6e6",
      borderColor: "#757575"
    }
  else if (riskScore >= 0.1 && riskScore <= 3.9)
    return {
      textColor: "#20b444",
      backgroundColor: "#ecf9ef",
      borderColor: "#20b444"
    }
  else if (riskScore >= 4.0 && riskScore <= 6.9)
    return {
      textColor: "#A08100",
      backgroundColor: "#FFEB96",
      borderColor: "#A08100"
    }
  else if (riskScore >= 7.0 && riskScore <= 8.9)
    return {
      textColor: "#894C02",
      backgroundColor: "#FFD8A7",
      borderColor: "#894C02"
    }
  else if (riskScore >= 9.0 && riskScore <= 10.0)
    return {
      textColor: "#e11900",
      backgroundColor: "#FBD2C4",
      borderColor: "#e11900"
    }
  else
    return {
      textColor: "#757575",
      backgroundColor: "#e6e6e6",
      borderColor: "#757575"
    }
}

export default function CredentialsLeaks() {
  const theme = useTheme();
  const navigate = useNavigate()

  // search variables:
  const [sortBy, setSortBy] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [page, setPage] = useState(1)
  const [search, setsearch] = useState("")
  const [victimType, setVictimType] = useState('all')
  const [risk, setRisk] = useState('all');
  const [fromDate, setFromDate] = useState<string | null>(null);



  const formatDate = (date: Date): string => {
    return date.toISOString().split('T')[0];
  };

  // Helper function to subtract days from current date
  const subtractDays = (days: number): string => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return formatDate(date);
  };

  const [lastDate, setLastDate] = useState<string>('all');


  // Generate date range values
  const dateRanges: DateRange[] = [
    { value: 'all', label: 'See all', date: null },
    { value: 'today', label: 'Today', date: formatDate(new Date()) },
    {
      value: 'last7',
      label: 'Last 7 days',
      date: subtractDays(7)
    },
    {
      value: 'last30',
      label: 'Last 30 days',
      date: subtractDays(30)
    }
  ];

  useEffect(() => {
    setFromDate(dateRanges.find((el => el.value === lastDate))?.date ?? null)
  }, [lastDate])


  const { data, isLoading, error } = useGetDataCredentials({
    page,
    sortBy,
    search,
    victimType,
    fromDate: fromDate ?? '',
    riskType:risk
  });


  const { notifs, notifsUser } = useSelector(notificationSelector)
  const [isDonwloaded, setisDonwloaded] = useState(false)



  useEffect(() => {

    document.title = "Credential Leaks"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  }, [])

  const setCredentialDetail = (item: any) => {
    const colors = renderColors(Math.round(Number(item?.total_risk_score)));

    const itemForDisplay = {
      ...item,
      textColor: colors.textColor,
      borderColor: colors.borderColor,
      bgColor: colors.backgroundColor
    };
    window.localStorage.setItem('credentialLeakDetail', JSON.stringify(itemForDisplay))
    navigate("/threatIntel/credentialLeaksDetail")
  }

  const handleChangePage = (
    _: React.ChangeEvent<unknown>, value: number
  ) => {
    setPage(value)
  };


  const handleSort = (column: string) => {
    // if (sortBy === column) {
    //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    // } else {
    //   setSortBy(column);
    //   setSortOrder('asc');
    // }
  };


  const sortedResult = useMemo(() => {
    return data?.data
  }, [data])

  const finalSort = sortedResult?.sort((a, b) => {
    let comparison = 0
    if (sortBy === 'username') {
      comparison = a.username.localeCompare(b.username)
    } else if (sortBy === 'url') {
      comparison = a.url.localeCompare(b.url)
    } else if (sortBy === 'total_risk_score') {
      comparison = Number(a?.total_risk_score).toFixed(1).localeCompare(Number(b?.total_risk_score).toFixed(1))

    }

    return sortOrder === 'asc' ? comparison : -comparison
  })

  const handleOpenNotf = () => setisDonwloaded(!isDonwloaded)

  const handleExportList = async () => {
    const result: any[] = await getCredentialLeakExcelApi()
    const dataXlsx = result?.map((item: any) => ({
      'UserName': item?.username,
      'Password': item?.password,
      'Url': item?.url,
      'Risk Score': item?.total_risk_score,
    }))
    setisDonwloaded(true)
    exportToExcel(dataXlsx || [], `Defendis-Credentials_Leaks-${moment(new Date()).format("MMMM DD, YYYY-HH:mm")}`);
  };

  return (
    <div style={{ marginTop: 20 }}>

      <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", height: "45px" }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 45, gap: 16 }}>

          <SearchField
            search={search}
            setsearch={setsearch}
          />
          <div style={{ display: 'flex', alignItems: 'center', gap: 10, color: "white", fontSize: "14px" }}>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={victimType} onChange={(value) => {
              setVictimType(value.target.value)
            }}
              style={{ fontSize: 14, fontWeight: 500 }} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginLeft: '-20px' }} />}

              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: '#1f1f1f',
                    borderRadius: '8px',
                    padding: '0px',
                    boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                    maxHeight: '360px',
                    overflowY: 'auto',
                  },
                },
                MenuListProps: {
                  dense: false,
                  disablePadding: true,
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}

            >
              <MenuItem value={'all'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>All victims</MenuItem>
              <MenuItem value={'Internal'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Internal</MenuItem>
              <MenuItem value={'External'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>External</MenuItem>
            </Select>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={risk} onChange={(e) => {
              setRisk(e.target.value)
            }} style={{ fontSize: 14, fontWeight: 500 }} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginLeft: '-20px' }} />}

              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: '#1f1f1f',
                    borderRadius: '8px',
                    padding: '0px',
                    boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                    maxHeight: '360px',
                    overflowY: 'auto',
                  },
                },
                MenuListProps: {
                  dense: false,
                  disablePadding: true,
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}

            >
              <MenuItem value={'all'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>All Risks</MenuItem>
              <MenuItem value={'critical'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Critical</MenuItem>
              <MenuItem value={'high'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>High</MenuItem>
              <MenuItem value={'medium'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Medium</MenuItem>
              <MenuItem value={'low'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Low</MenuItem>
            </Select>
            <Select labelId="demo-simple-select-label" id="demo-simple-select"
              value={lastDate}
              onChange={e => {
                setLastDate(e.target.value)
              }}
              style={{ fontSize: 14, fontWeight: 500 }} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginLeft: '-20px' }} />}

              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: '#1f1f1f',
                    borderRadius: '8px',
                    padding: '0px',
                    boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                    maxHeight: '360px',
                    overflowY: 'auto',
                  },
                },
                MenuListProps: {
                  dense: false,
                  disablePadding: true,
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}

            >
              {dateRanges.map((range) => (
                <MenuItem
                  key={range.value}
                  value={range.value}
                  sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}                >
                  {range.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className={styles.removeSelected} onClick={handleExportList}>
          <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
          <FontAwesomeIcon icon={faDownload} color='white' style={{ fontSize: 14 }} />
        </div>

      </div>
      {
        !isLoading ?
          sortedResult && sortedResult.length !== 0 ?
            <div style={{ width: "100%" }}>
              <table className={styles.table}>
                <thead>
                  <tr className={styles.tableHead} >
                    <th></th>
                    <th style={{ paddingLeft: 8 }} onClick={() => handleSort('username')}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Username</span>
                        <FontAwesomeIcon
                          icon={sortBy === 'username' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                          size="1x"
                          height={12}
                          width={11}
                        />
                      </div>
                    </th>
                    <th style={{ paddingLeft: 8 }}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Password</span>
                      </div>
                    </th>
                    <th style={{ paddingLeft: 8 }} onClick={() => handleSort('username')}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Source</span>
                        <FontAwesomeIcon
                          icon={sortBy === 'username' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                          size="1x"
                          height={12}
                          width={11}
                        />
                      </div>
                    </th>
                    <th style={{ paddingLeft: 8 }} onClick={() => handleSort('total_risk_score')}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Risk Score</span>
                        <FontAwesomeIcon
                          icon={sortBy === 'total_risk_score' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                          size="1x"
                          height={12}
                          width={11}
                        />
                      </div>
                    </th>
                    <th style={{ paddingLeft: 8 }} onClick={() => handleSort('log_date')}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Leaked on</span>
                        <FontAwesomeIcon
                          icon={sortBy === 'log_date' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                          size="1x"
                          height={12}
                          width={11}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    finalSort?.map(item =>
                      <tr key={item.passwordid} className={styles.tableActivityRowDomainBrand} onClick={() => setCredentialDetail(item)}>
                        <td className={styles.tableCell} style={{ width: 6 }}>
                        </td>
                        <td className={styles.tableCell}>
                          {(item?.username ? item?.username : "-").toLowerCase()}
                        </td>
                        <td className={styles.tableCell} style={{ paddingLeft: 8, width: 100 }}>
                          {item.password ? `${item.password.slice(0, 3)}****` : "-"}
                        </td>
                        <td className={styles.tableCell} style={{ paddingLeft: 8 }}>
                          {item?.url?.includes('android') ? item?.url?.split('@')[1] : item?.url?.substring(0, 43)}
                        </td>
                        <td className={styles.tableCell} style={{ padding: "13px 0px", width: 150 }}>
                          <span className={styles.scoreSeverity} style={{
                            width: '22px',
                            color: renderColors(Math.round(Number(item?.total_risk_score) * 10) / 10).textColor,
                            border: `1px solid ${renderColors(Math.round(Number(item?.total_risk_score) * 10) / 10).borderColor}`,
                            backgroundColor: renderColors(Math.round(Number(item?.total_risk_score) * 10) / 10).backgroundColor
                          }}>{
                              Number(item?.total_risk_score).toFixed(1)
                            }</span>
                        </td>
                        <td className={styles.tableCell} style={{ paddingLeft: 8, width: 150 }}>
                          <div style={{}}>
                            {
                              item?.user ? (
                                <div style={{ display: 'flex', alignItems: "center", gap: 10, justifyContent: "start" }}>
                                  <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="grey" />
                                  <span style={{ fontSize: 14 }}>
                                    {item.user.log_date && moment(item.user.log_date).format('MMM DD, YYYY')}
                                  </span>
                                </div>
                              ) : "-"
                            }
                          </div>
                        </td>

                      </tr>
                    )
                  }
                </tbody>
              </table>
              <Pagination
                showFirstButton
                showLastButton
                shape="rounded"
                page={Number(data?.pagination?.currentPage)}
                count={data?.pagination?.totalPages}
                onChange={handleChangePage}
                sx={{
                  '& .MuiPagination-ul': {
                    justifyContent: "center",
                    display: 'flex'
                  },
                  '& .Mui-selected': {
                    color: '#ffffff', // Text color for active page
                    backgroundColor: '#1f1f1f', // Background color for active page
                    opacity: 0.3
                  },
                  '& .MuiPaginationItem-root': {
                    color: '#ffffff', // Text color for inactive pages
                  },
                }}
              />
            </div>
            :
            <div className={styles.loaderVul}>
              <EmptyState />
            </div>
          :
          <div className={styles.loaderVul}>
            <CircularProgress />
          </div>
      }

      <Snackbar
        open={isDonwloaded}
        autoHideDuration={3000}
        onClose={handleOpenNotf}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert severity={"success"} variant={"filled"} sx={{ width: '100%', backgroundColor: '#00B0FF', borderRadius: 12 }}
          iconMapping={{
            success: <FontAwesomeIcon icon={faCircleCheck} height={28} width={28} color='white' />,
            error: <FontAwesomeIcon icon={faCircleXmark} height={28} width={28} color='white' />
          }}>
          {"List exported successfully"}
        </Alert>
      </Snackbar>

    </div>
  )
}


{/* <Pagination 
        
          onChange={handleChangeNextPage}
          page={localStorage.getItem('currentPage')?parseInt(JSON.parse(localStorage.getItem('currentPage')||"1")):1}
          count={credentialsLeak?.pagination?.totalRecords  }
           shape="rounded"
           sx={{
            "& .MuiPaginationItem-root": {
              color: '#333333',  // Sets color for all numbers
          },
          "& .Mui-selected": {
              backgroundColor: '#1f1f1f',  // Sets background for the current page
              color: 'white',  // Optional: Change text color for the selected item
          },
          "& .MuiPaginationItem-root:hover": {
              backgroundColor: theme.palette.action.hover,  // Optional: Change on hover
          }
           }}
           /> */}