import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../common/Header';
import Menu from '../../common/Menu';
import styles from '../../modules.module.scss';
import Victims from '../groups/groupDetail/victims';
import CryptoWallet from '../groups/groupDetail/cryptoWallet';
import ExternalInfo from '../groups/groupDetail/externalInfo';
import NegociationChats from '../groups/groupDetail/negociationChats';
import RansomeNote from '../groups/groupDetail/ransomeNote';
import Urls from '../groups/groupDetail/urls';
import { useSelector } from 'react-redux';
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector';
import moment from 'moment';
import { getGroupDescAPI, getGroupUrlsAPI } from '../../../../core/api/ransomeApi';

function GroupDetail() {
    const navigate = useNavigate();
    const { groupDetail } = useSelector(ransomeSelector);

    const [activeCompo, setActiveCompo] = useState('urls');
    const [desc, setDesc] = useState(null);
    const [dataGroups, setDataGroups] = useState(null);
    const [isLoadingDesc, setIsLoadingDesc] = useState(false);
    const [isLoadingUrls, setIsLoadingUrls] = useState(false);

    const getGroupDesc = async () => {
        setIsLoadingDesc(true);
        try {
            if (groupDetail?.name) {
                const response = await getGroupDescAPI(groupDetail.name);
                setDesc(response);
            } else {
                setDesc(null);
            }
        } catch (error) {
            console.error('Error fetching group description:', error);
            setDesc(null);
        }
        setIsLoadingDesc(false);
    };

    const getGroupsUrl = async () => {
        setIsLoadingUrls(true);
        try {
            if (groupDetail?.name) {
                const response = await getGroupUrlsAPI(groupDetail.name);
                setDataGroups(response);
            } else {
                setDataGroups(null);
            }
        } catch (error) {
            console.error('Error fetching group URLs:', error);
            setDataGroups(null);
        }
        setIsLoadingUrls(false);
    };

    useEffect(() => {
        if (groupDetail?.name) {
            getGroupDesc();
            getGroupsUrl();
        }
    }, [groupDetail?.name]); // Dependency to trigger only when groupDetail.name is available

    const renderSections = () => {
        switch (activeCompo) {
            case 'urls':
                return <Urls urls={dataGroups} />;
            case 'externalInfo':
                return <ExternalInfo />;
            case 'ransomNote':
                return <RansomeNote />;
            case 'cryptoWallet':
                return <CryptoWallet />;
            case 'negoChat':
                return <NegociationChats />;
            case 'victims':
                return <Victims />;
            default:
                return null;
        }
    };

    return (
        <div className={styles.main}>
            <Header />
            <Menu />
            <div className={styles.content}>
                <div className={styles.personalInfoHeader}>
                    <div>
                        <div
                            onClick={() => navigate('/ransomwareIntel')}
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                                fontSize: '12px',
                                color: '#afafaf',
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                            <span>Go Back</span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                                marginTop: 4,
                            }}
                            className={styles.personnalInfoHeadr_firstSection_personnalAccount}
                        >
                            <span style={{ fontSize: 20, fontWeight: 600 }}>
                                {groupDetail?.name
                                    ? groupDetail?.name.charAt(0).toUpperCase() +
                                      groupDetail?.name.slice(1)
                                    : ''}
                                {' '}Group
                            </span>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 32, fontSize: 14 }}>
                    <span style={{ color: '#afafaf' }}>Last updated </span>
                    <span style={{ color: 'white' }}>
                        {moment(groupDetail?.updated).format('MMMM DD, YYYY')}
                    </span>
                </div>

                <div
                    style={{
                        backgroundColor: '#141414',
                        width: 'fit-content',
                        marginTop: 32,
                        justifyContent: 'center',
                        borderRadius: 6,
                        padding: '4px 6px',
                    }}
                    className={styles.firstSectionMenu}
                >
                    <span
                        style={{ cursor: 'pointer', color: 'white', fontSize: 14, fontWeight: 500 }}
                        onClick={() => setActiveCompo('urls')}
                        className={activeCompo === 'urls' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}
                    >
                        Communities
                    </span>
                    <span
                        style={{ cursor: 'pointer', color: 'white', fontSize: 14, fontWeight: 500 }}
                        onClick={() => setActiveCompo('victims')}
                        className={activeCompo === 'victims' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}
                    >
                        Victims
                    </span>
                </div>
                {renderSections()}
            </div>
        </div>
    );
}

export default GroupDetail;
