import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import styles from '../../../modules.module.scss'
import EmptyState from '../../../common/EmptyState'


export default function MalwarePath({ user }: any) {

  useEffect(() => {
    document.title = "User Profile - Malware Path"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [])


  return (
    <>
      {
        user?.malware_path && user?.malware_path?.length!==0 ?

          <table className={styles.table} style={{ marginTop: 8 }}>
            <tbody>
              {
                user?.malware_path ?
                  <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '805px', padding: '15px 16px', color: '#ffffff' }} className={styles.tableCell}>
                      {user?.malware_path}
                    </td>
                    <td style={{ display: "flex", justifyContent: "end", padding: '15px 16px', color: '#afafaf' }} className={styles.tableCell}>
                      {user?.hwid}
                    </td>
                  </tr>
                  : <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '805px', padding: '15px 16px' }} className={styles.tableCell}>
                      {user?.hwid}
                    </td>
                  </tr>
              }
            </tbody>
          </table>
          :
          <div className={styles.loaderVul}>
            <EmptyState />
          </div>
      }
    </>
  )
}
