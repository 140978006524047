import { faComment, faFolder, faHouse, faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectChangeEvent } from '@mui/material';
import React from 'react';

import logoPersonalAccount from "../../../icons/settings/menu/personal.svg"
import logoSecurity from "../../../icons/settings/menu/security.svg"
import logoEntreprise from "../../../icons/settings/menu/entreprise.svg"
import logoUsers from "../../../icons/settings/menu/users.svg"
import logoWatchList from "../../../icons/settings/menu/assets.svg"
import logoNotifications from "../../../icons/settings/menu/notifications.svg"
import logoSupport from "../../../icons/settings/menu/support.svg"

export interface menuItem {
    id: number;
    title: string;
    icon: any;
    path: string;
}


export const menuItems: menuItem[] = [
    {
        id: 1,
        title: "Profile",
        icon: <img src={logoPersonalAccount} height={18} width={18} />,
        path: '/profile'
    },
    {
        id: 2,
        title: "Account Security",
        icon: <img src={logoSecurity} height={18} width={18} />,
        path: '/security'
    },
    {
        id: 3,
        title: "Workspace Settings",
        icon: <img src={logoEntreprise} height={18} width={18} />,
        path: '/workspace'
    },
    {
        id: 4,
        title: "Members",
        icon: <img src={logoUsers} height={18} width={18} />,
        path: '/members'
    },
    {
        id: 5,
        title: "Watchlists",
        icon: <img src={logoWatchList} height={18} width={18} />,
        path: '/watchlists'
    },
    {
        id: 6,
        title: "Notifications",
        icon: <img src={logoNotifications} height={18} width={18} />,
        path: '/communication'
    },
    {
        id: 7,
        title: "Get Support",
        icon: <img src={logoSupport} height={18} width={18} />,
        path: '/support'
    },
]

export interface PersonalInformation {
    firstName: string;
    lastName: string;
    email: string;
    phonecode: string;
    phone: string;
    secondaryEmail: string;
}

export type typesHandleChange = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent