import { faBell, faChevronDown, faCircleXmark, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import styles from '../../modules.module.scss'
import ReactGA from 'react-ga4';

export default function ToSubmit() {
    useEffect(() => {
      document.title = "Compliance - To Submit"
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    }, [])
    
    return (
        <div style={{marginTop:20}}>
            <div className={styles.searchAndIcon} style={{height:45}}>
                    <input className={styles.search} name='search' placeholder='Search'/>
                    <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                </div>

                <div className={styles.toSubmitView}>
                    <div className={styles.tableSubmit}>
                    <table className={styles.table} >
                            <tr className={styles.tableHead}>
                            <th style={{paddingLeft: 8}}>
                                Username {" "}
                                <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                            </th>
                            <th style={{paddingLeft: 8}}>
                                Password {" "}
                                <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                            </th>
                            <th style={{paddingLeft: 8}}>
                                Source {" "}
                                <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                            </th>
                            <th style={{paddingLeft: 8}}></th>
                            </tr>
                            <tr className={styles.tableActivityRowUsers} >
                                <td style={{width: '255px', padding: '10px 16px'}} className={styles.tableCell}>
                                    p.beasley@dundermifflin.com
                                </td>
                                
                                <td style={{width: '180px', padding: '10px 16px'}} className={styles.tableCell} >
                                    HRct76*****
                                </td>
                                <td style={{width: '255px', padding: '10px 16px'}} className={styles.tableCell} >
                                    https://member.dundermifflin.com
                                </td>
                                <td style={{width: '80px', padding: '10px 16px'}} className={styles.tableCell} >
                                    <FontAwesomeIcon icon={faCircleXmark} width={11} height={12} color="#afafaf"/>
                                </td>
                                </tr>
                            
                            
                        </table>
                    </div>
                    <div style={{backgroundColor: '#141414', borderRadius: 16, padding: 16}} className={styles.descToSubmit}>
                        <p style={{color: 'white', fontSize: 14}}>When a data breach has occurred, you need to consider the severity and the potential negative consequences of this breach</p>
                        <p style={{color: 'white', fontSize: 14}}>If you decide not to report it, keep the necessary documentation in order to justify this decision.</p>
                        <p style={{color: 'white', fontSize: 14}}>Credentials to submit: <span style={{color: '#afafaf'}}>30</span></p>
                        <Button style={{backgroundColor: 'white', display: 'flex', alignItems: 'center', gap: 10, height: '48px'}}>
                            <p style={{fontWeight: '500', fontSize: 14}}>Notify Authorities</p>
                            <FontAwesomeIcon icon={faBell} height={12} width={11}/>
                        </Button>
                    </div>
                </div>
        </div>
    )
}
