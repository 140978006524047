import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { RouterProvider, useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import router from './routers';
import configureStore from './core/store';
import { theme } from './core/theme';
import { checkIfTokenExpiredApi } from './core/api/authApi';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const store = configureStore();



function App() {
  const queryClient = new QueryClient()
  ReactGA.initialize('G-R06MVCK7EX');
  useEffect(() => {


    window.addEventListener('error', (error) => {
      // console.log(JSON.stringify(error));
    })
  }, [])

  return (
    <Provider store={store}>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
