import React from 'react'
import styles from '../../../modules.module.scss'
import { Rating } from '@mui/material'
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { brandIntelSelector } from '../../../../../core/selectors/brandIntelSelector';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    stroke: '#545454', // Border color for empty stars
    strokeWidth: 0.5, // Border width
  },
});

function InformationsPlaceDetail() {

  const { placeDetail } = useSelector(brandIntelSelector)
  // console.log(placeDetail)
  return (
    <table className={styles.table} style={{ marginTop: 25 }}>
      <tbody>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '200px', padding: '14px 16px' }} className={styles.tableCell}>
            Street
          </td>
          <td style={{ width: '918px', padding: '14px 16px' }} className={styles.tableCell}>
            {placeDetail?.street}
          </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '200px', padding: '14px 16px' }} className={styles.tableCell}>
            City
          </td>
          <td style={{ width: '918px', padding: '14px 16px' }} className={styles.tableCell}>
            {placeDetail?.city}
          </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '200px', padding: '14px 16px' }} className={styles.tableCell}>
            Postal Code
          </td>
          <td style={{ width: '918px', padding: '14px 16px' }} className={styles.tableCell}>
            {placeDetail?.postalCode ? placeDetail?.postalCode : "No Postal Code"}
          </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '200px', padding: '14px 16px' }} className={styles.tableCell}>
            Country
          </td>
          <td style={{ width: '918px', padding: '14px 16px' }} className={styles.tableCell}>
            {placeDetail?.country}
          </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '200px', padding: '14px 16px' }} className={styles.tableCell}>
            Longitude, Latitude
          </td>
          <td style={{ width: '918px', padding: '14px 16px' }} className={styles.tableCell}>
            {placeDetail?.longitude}, {placeDetail?.latitude}
          </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '200px', padding: '14px 16px' }} className={styles.tableCell}>
            Stars
          </td>
          <td style={{ width: '918px', padding: '14px 16px' }} className={styles.tableCell}>
            <StyledRating name="read-only" value={placeDetail?.rating} readOnly size="small" />
          </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '200px', padding: '14px 16px' }} className={styles.tableCell}>
            Phone Number
          </td>
          <td style={{ width: '918px', padding: '14px 16px' }} className={styles.tableCell}>
            {placeDetail?.phone ? placeDetail?.phone : "No Phone Number"}
          </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '200px', padding: '14px 16px' }} className={styles.tableCell}>
            Accessibility
          </td>
          <td style={{ width: '918px', padding: '14px 16px' }} className={styles.tableCell}>
            {placeDetail?.accessibility?.[0]?.['Wheelchair accessible entrance'] ? "Yes" : "No"}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default InformationsPlaceDetail