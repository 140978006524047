import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { LoginRequestAction, setNotificationAction } from '../../core/actions/authActions';
import { authSelector } from '../../core/reducers/authReducer';
import styles from './auth.module.scss';
import ErrorNotification from '../settings/common/errorNotification';
import logoSignSVG from '../../icons/auth/Login-Illustration.svg';
import Logo from '../../icons/main/identity/defendisWhite.svg';
import AuthFooter from './components/authFooter';

// Types and Interfaces
interface FormData {
    email: string;
    password: string;
}

interface FormErrors {
    email: string;
    password: string;
}

type ValidationField = keyof FormData;

const FirstTimeLogin: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const { error, authenticated, loginEntreprise, errorCode, isLoadingLogin, jwt } = auth;
    // Form state
    const [formData, setFormData] = useState<FormData>({
        email: '',
        password: ''
    });
    const [formErrors, setFormErrors] = useState<FormErrors>({
        email: '',
        password: ''
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [openNotification, setOpenNotification] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false);

    // Validation rules
    const validateField = (name: ValidationField, value: string): string => {
        switch (name) {
            case 'email':
                if (!value) return 'Email is required';
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
                    return 'Invalid email address';
                }
                return '';
            case 'password':
                if (!value) return 'Password is required';
                if (value.length < 8) {
                    return 'Password must be at least 8 characters';
                }
                return '';
            default:
                return '';
        }
    };

    // Handle input changes
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        // Real-time validation
        const error = validateField(name as ValidationField, value);
        setFormErrors(prev => ({
            ...prev,
            [name]: error
        }));
    };

    // Validate all fields
    const validateForm = (): boolean => {
        const errors: Partial<FormErrors> = {};
        (Object.keys(formData) as ValidationField[]).forEach(key => {
            const error = validateField(key, formData[key]);
            if (error) errors[key] = error;
        });
        setFormErrors(errors as FormErrors);
        return Object.keys(errors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (validateForm()) {
            setLoading(true);
            dispatch(LoginRequestAction({
                email: formData.email,
                password: formData.password
            }));

            setTimeout(() => {
                dispatch(setNotificationAction({
                    success: true,
                    error: '',
                    errorCode: null
                }));
                setLoading(false);
                setOpenNotification(false);
            }, 3500);
        }
        setIsSubmitting(false);
    };

    // Effects
    useEffect(() => {
        document.title = 'Sign In';
        if (authenticated && !loginEntreprise) {
            dispatch(setNotificationAction({
                error: 'Contact your account manager to finish the Onboarding',
                errorCode: 400,
                success: true
            }));
            setOpenNotification(true);
        } else if (loading && loginEntreprise) {
            navigate("/");
            setLoading(false);
        }
    }, [authenticated, loginEntreprise, jwt, error, dispatch, loading, navigate]);

    const handleCloseNotification = (): void => {
        setOpenNotification(false);
    };

    return (
        <div style={{ backgroundColor: '#141414', width: '100vw', height: '100vh', position: 'relative' }}>
            <div style={{ position: 'absolute', top: 0, left: 0, padding: '6px 0px', cursor: 'pointer' }} onClick={() => navigate('/')}>
                <img src={Logo} className={styles.logo} alt="Defendis Logo" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '95vh' }}>
                <div className={styles.login} style={{ width: 'auto' }}>
                    <div style={{ paddingTop: '3rem', padding: '4rem' }}>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.loginInputs} style={{ marginTop: "-1em" }}>
                                <span className={styles.titleLogin}>Sign in</span>
                                <TextField
                                    InputProps={{ sx: { fontSize: 14 } }}
                                    onChange={handleChange}
                                    id="email"
                                    name="email"
                                    label="Work Email"
                                    variant="standard"
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.email}
                                    helperText={formErrors.email}
                                    value={formData.email}
                                />
                                <TextField
                                    InputProps={{ sx: { fontSize: 14 } }}
                                    onChange={handleChange}
                                    id="password"
                                    name="password"
                                    label="Password"
                                    variant="standard"
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.password}
                                    helperText={formErrors.password}
                                    value={formData.password}
                                />
                                <NavLink
                                    to="/forgotPassword"
                                    style={{ marginTop: 0, float: 'right' }}
                                    className={styles.infoText}
                                >
                                    Forgot Password?
                                </NavLink>
                                <Button
                                    type="submit"
                                    disabled={isLoadingLogin || isSubmitting}
                                    className={styles.btnLogin}
                                    style={{
                                        marginTop: 48,
                                        fontSize: 14,
                                        fontWeight: 500,
                                        cursor: (isLoadingLogin || isSubmitting) ? "progress" : "pointer"
                                    }}
                                >
                                    Continue
                                </Button>
                            </div>
                        </form>
                    </div>
                    <div style={{ paddingTop: '2rem', display: window.innerWidth < 768 ? 'none' : 'block' }}>
                        <img src={logoSignSVG} alt="Illustration" />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', gap: 16, justifyContent: 'center', alignItems: 'center' }}>
                <AuthFooter />
            </div>
            {openNotification && auth.error !== "" && auth.errorCode && (
                <ErrorNotification
                    severity={errorCode !== 200 ? 'error' : 'success'}
                    open={openNotification}
                    message={error}
                    handleClose={handleCloseNotification}
                />
            )}
        </div>
    );
};

export default FirstTimeLogin;