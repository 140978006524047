import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import styles from '../../modules.module.scss'
import { getVictimsByCountryApi } from '../../../../core/api/ransomeApi'
import ReactGA from 'react-ga4';
import Loader from "../../../icons/Loader.svg"
import { CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_RANSOME_COUNTRIES, SET_COUNTRY_CODE, SET_LAST_UPDATED_COUNTRY, SortVul } from '../../../../core/constants'
import { CircleFlag } from 'react-circle-flags'
import { byIso } from 'country-code-lookup'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
import EmptyStateCountries from '../../common/EmptyStateCountries'
import SearchField from '../../components/searchField/SearchField';


function CountriesSection() {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { countries, isLoadingCountries } = useSelector(ransomeSelector)
    const [search, setsearch] = useState('')
    const scrollableDivRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const savedScrollPosition = location.state?.scrollPosition ?? parseInt(sessionStorage.getItem('scrollPosition') || '0');

        setTimeout(() => {
            if (scrollableDivRef.current) {
                scrollableDivRef.current.scrollTop = savedScrollPosition;
            } else {
            }
        }, 50);
    }, [location]);

    const handleNavigation = (url: string) => {
        const currentScrollPosition = scrollableDivRef.current?.scrollTop || 0;
        sessionStorage.setItem('scrollPosition', currentScrollPosition.toString());
        navigate(url, { state: { scrollPosition: currentScrollPosition } });
    };

    const resultSort = countries?.sort((a, b) => a?.countryName?.localeCompare(b?.countryName))
    useEffect(() => {
        if (!countries) {
            const data: SortVul = {
                search: ""
            }
            dispatch({
                type: REQUEST_RANSOME_COUNTRIES,
                payload: data
            })
        }
        document.title = "Ransomware Countries"
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    }, [])

    const results = countries

    const handleSearch = () => {
        const data: SortVul = {
            search: search
        }
        dispatch({
            type: REQUEST_RANSOME_COUNTRIES,
            payload: data
        })
    }

    const setCountryCode = (code: string, lastUpdate: string) => {
        dispatch({
            type: SET_COUNTRY_CODE,
            payload: code
        })
        dispatch({
            type: SET_LAST_UPDATED_COUNTRY,
            payload: lastUpdate
        })
        handleNavigation('/ransomwareIntel/countryDetail')
    }

    return (
        <div className={styles.awaitingApproval} style={{ marginTop: 18 }}>
            <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", height: "45px" }}>
                <SearchField
                    search={search}
                    setsearch={setsearch}
                    handleSearch={handleSearch}
                />
            </div>
            {
                !isLoadingCountries ?
                    countries && countries.length !== 0 ?
                        <div ref={scrollableDivRef} className={styles.countries} style={{ marginTop: 18 }}>
                            {
                                results?.map(item =>
                                    item.countryName ? (
                                        <div className={styles.countriesGrid} onClick={() => setCountryCode(item?.country, item?.last_publish_date)} style={{ border: '1px solid  #242424', borderRadius: 8, padding: '12px 16px', cursor: "pointer" }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 24 }} >
                                                <div style={{ height: '32px', width: 24, borderRadius: '50%' }}>
                                                    <CircleFlag countryCode={item?.country?.toLowerCase()} height="32" />
                                                </div>
                                                <div style={{ display: 'grid', gap: 5 }}>
                                                    <span style={{ fontSize: 14, color: 'white', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', maxWidth: '100%' }}> {item.countryName ?? 'Unknown country'}</span>
                                                    <span style={{ color: '#757575', fontSize: 12 }}>{Number(item?.victim_count) === 0 ? 'No attacks' : `${Number(item?.victim_count)} ${Number(item?.victim_count) === 1 ? 'attack' : 'attacks'}`}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                )
                            }
                        </div>
                        :
                        <div className={styles.loaderVul}>
                            <EmptyStateCountries />
                        </div>
                    :
                    <div className={styles.loaderVul}>
                        <CircularProgress />

                    </div>
            }
        </div>
    )
}

export default CountriesSection
