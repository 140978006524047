import React, { FormEvent, useEffect, useState } from 'react'
import styles from '../../settings.module.scss'
import { useDispatch } from 'react-redux'
import { getInfoSecurityPageRequest, getUserInfoRequest } from '../../../../core/actions/userActions'
import { SecurityPage, UserInfo } from '../../../../core/constants'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../../core/reducers/userReducer'

type TypeSetFormState = UserInfo | SecurityPage

interface props {
  handleSubmit : (event: FormEvent<HTMLFormElement>) => void
  setformState: (value: React.SetStateAction<TypeSetFormState>) => void,
  dataIsUpdated: boolean
  title: string;
}

function HeaderPersonalInfo({handleSubmit, setformState, title, dataIsUpdated}:props) {
  const dispatch = useDispatch()
  const [shouldUpdate, setshouldUpdate] = useState<boolean>(false)
  const { userInfo } = useSelector(userSelector)

    // useEffect(() => {
    //     setTimeout(() => {
    //       dispatch(getUserInfoRequest())
    //     }, 500);
    //   }, [shouldUpdate])

  const handleCancel = () => {
    dispatch(getUserInfoRequest())
    setshouldUpdate(!shouldUpdate)
  }

  

  return (
    <form onSubmit={handleSubmit} className={styles.personalInfoHeader}>
        <div className={styles.personnalInfoHeadr_firstSection}>
            <p className={styles.personnalInfoHeadr_firstSection_settings}>Settings</p>
            <p className={styles.personnalInfoHeadr_firstSection_personnalAccount}>{title}</p>
        </div>
        {
          dataIsUpdated && <div className={styles.personnalInfoHeader_secondSection}>
          <button onClick={()=>{
            setshouldUpdate(!shouldUpdate)
          }} type="submit"  className={styles.personnalInfoHeader_secondSection_Save}>Save</button>
          <button type='button' onClick={handleCancel} className={styles.personnalInfoHeader_secondSection_Cancel}>Cancel</button>
      </div>
        }
        </form>
  )
}

export default HeaderPersonalInfo