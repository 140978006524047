import React from 'react'
import styles from "../modules.module.scss"
 
import EmptyStateLogo from "../../../icons/main/states/emptyStateUsers.svg"
import { useSelector } from 'react-redux'
import { userSelector } from '../../../core/reducers/userReducer'

interface props {
    open: boolean,
    setOpen: any
}

function EmptyStateUsers({open, setOpen}: props) {
  const { roleUser, userInfo } = useSelector(userSelector)
  return (
    <div className={styles.emptyState}>
    <div style={{width: '320px', margin: 32}}>
        <div style={{display: "flex", justifyContent:"center",alignItems: "center"}}>
          <img src={EmptyStateLogo}/>
        </div>
        <div style={{marginTop: 40}}> 
          <h2 style={{textAlign: "center",color:"white", fontWeight:"600", fontSize:20, marginBottom: 0}}>No Active Members</h2>
          <p style={{textAlign: "center",color:"#AFAFAF", fontWeight:'400', fontSize: 14, marginBottom: 16}}>Invite your team and partners to collaborate and fix your vulnurabilities.</p>
        </div>
    </div>
  </div>
  )
}

export default EmptyStateUsers