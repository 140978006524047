import { Reducer } from 'redux'
import { Entreprise, EntrepriseActions, EntrepriseSupaBase, ExpiredToken, GET_SECURITY_PAGE, GET_USER_INFO_REQUEST, GET_USER_INFO_SUCCESS, REQUEST_GET_ALL_ENTREPRISES, Roles, SAVE_USER_ENTREPRISE, SET_ENTREPRISE_PICTURE_PROFILE, SET_INFOS_ENTREPRISE, SET_USERS_ENTREPRISE, SUCCESS_GET_ALL_ENTREPRISES, Security, SecurityPage, UPDATE_INFOS_ENTREPRISE, UPDATE_SECURITY_PAGE, UPDATE_USER_INFO_REQUEST, UPDATE_USER_INFO_SUCCESS, User, UserAction, UserEntrepriseReq, UserInfo, UserReq, UsersEntreprise } from "../constants";

interface entrepriseSelectorState {
    entreprise: Entreprise
    users: UsersEntreprise[]
    isLoadingEntreprises: boolean;
    entreprises: EntrepriseSupaBase[] | null
}


interface State {
    users: UsersEntreprise[];
    entreprise: Entreprise;
    userReq: UserEntrepriseReq
    isLoadingEntreprises: boolean;
    entreprises: EntrepriseSupaBase[] | null
}

const initialDataEntreprise: Entreprise = {
    id: null,  // Initializing bigint with 0n (BigInt literal)
  created_at: new Date().toISOString(),  // ISO 8601 string for the current time
  entrepriseName: null,  // Since this can be null
  ein: null,  // Assuming 'ein' (Employer Identification Number) can be null
  type: null,  // Assuming EntrepriseTypeSupaBase enum can be null initially
  city: null,  // Assuming city can be null
  country: null,  // Assuming country can be null
  subscription: null,  // BigInt for subscription ID, initially null
  subStart: null,  // Subscription start date, null initially
  subEnd: null,  // Subscription end date, null initially
  extraUsers: 0,  // Assuming 0 as the initial number of extra users
  extraIndicators: null,  // Initializing bigint with 0n for extra indicators
  avatarUrl: null,  // Initial avatar URL is null
  active: null,  // Assuming active status can be null, for inactive or unknown state
  updated_at: null,  // Last updated date, null initially
  subscriptionName: '',
  subsciptionsOptions: null
}

const initialDataUser: UserEntrepriseReq =  {
    firstname: '',
    lastname: '',
    username: '',
    jwt: ''
}

const initialDataUsersEntreprise: UsersEntreprise[] = []

const initialData: State = {
    entreprise: initialDataEntreprise,
    users: initialDataUsersEntreprise,
    userReq:initialDataUser,
    entreprises: null,
    isLoadingEntreprises: false
}


export const entrepriseReducer:Reducer<State, EntrepriseActions> = (state,action): State =>  {
    if(state === undefined) return initialData;
    switch(action.type) {
        
        case SET_INFOS_ENTREPRISE:
        case SAVE_USER_ENTREPRISE:
        case UPDATE_INFOS_ENTREPRISE:
        case SET_USERS_ENTREPRISE:
            return {
                ...state,
                ...action.payload as any
            }
        case SET_ENTREPRISE_PICTURE_PROFILE:
            return {
                ...state,
                entreprise: {
                    ...state.entreprise,
                    avatarUrl: action.payload as string
                }
            }
        case REQUEST_GET_ALL_ENTREPRISES:
            return {
                ...state,
                isLoadingEntreprises: true
            }
        case SUCCESS_GET_ALL_ENTREPRISES:
            return {
                ...state,
                isLoadingEntreprises: false,
                entreprises: action.payload as EntrepriseSupaBase[]
            }
        case REQUEST_GET_ALL_ENTREPRISES:
            return {
                 ...state,
                 isLoadingEntreprises: false
            }
                                
        default:
            return state;
    }
}

export const entrepriseSelector = ((state: entrepriseSelectorState) => state.entreprise)
export const usersSelector = ((state: entrepriseSelectorState) => state.users)