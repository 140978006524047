import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import styles from '../../settings.module.scss';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { entrepriseSelector } from '../../../../core/reducers/entrepriseReducer';
import { getInfosEntrepriseRequest, setPictureEntrepriseAction } from '../../../../core/actions/entrepriseActions';
import { removeEntreprisePictureAPI, uploadEntreprisePictureProfileAPI } from '../../../../core/api/entrepriseApi';
import EmptyEntrepriseLogo from '../../../../icons/main/identity/defaultUser/emptyEntreprise.jpg'
import { userSelector } from '../../../../core/reducers/userReducer';

const Input = styled('input')({
  display: 'none',
});

const AvatarUploadEntreprise: React.FC = () => {
  const dispatch = useDispatch()
  const entreprise = useSelector(entrepriseSelector)
  const { roleUser } = useSelector(userSelector)
  const [avatarSrc, setAvatarSrc] = useState<string>(entreprise.avatarUrl ? entreprise.avatarUrl : EmptyEntrepriseLogo);

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const img: File = event.target.files[0];
      setAvatarSrc(URL.createObjectURL(img));
      const formData: FormData = new FormData()
      formData.append('profilePicture', img);
      uploadAvatarFile(formData)
      setTimeout(() => {
        dispatch(getInfosEntrepriseRequest())
      }, 3000);
    }
  };

  const handleRemoveAvatar = async () => {
    const dataSrc = await removeEntreprisePictureAPI()
    setAvatarSrc(dataSrc);
    setTimeout(() => {
      dispatch(getInfosEntrepriseRequest())
    }, 500);
  };

  const uploadAvatarFile = async (formData: FormData, isRemoval = false) => {

    const response = await uploadEntreprisePictureProfileAPI(formData);
    if (!isRemoval) {
      dispatch(setPictureEntrepriseAction(response || ""));
      dispatch(getInfosEntrepriseRequest())
    } else {
      dispatch(setPictureEntrepriseAction(""));  // Set to an empty string or appropriate default on removal
    }
  }



  return (
    <div className={styles.avatarUpload}>
      <img src={avatarSrc} style={{ width: 56, height: 56, overflow: "hidden", borderRadius: 8 }} />
      <div style={{ marginTop: 0, display: 'grid', alignContent: 'center', justifyContent: 'center' }}>
        <label htmlFor="avatar-upload-input" >
          <p  style={{fontSize:14, color: 'white', fontWeight: 500, paddingBottom: 4 }}>Brand Indetity</p>
        </label>
        <div className={styles.uploadAndRemoveAvatar}>
          <p style={{fontSize:12, color: 'grey'}}>Contact Support to change your identity</p>
        </div>
      </div>
      {/*
        roleUser?.companySettings ? avatarSrc ? (
          <div style={{marginTop: 0, display: 'grid', alignContent: 'center', justifyContent:'center'}}>
              <label htmlFor="avatar-upload-input" >
                <Input accept="image/*" id="avatar-upload-input" type="file" onChange={handleAvatarChange} />
                <p className={styles.uploadAvatarBtn} style={{paddingBottom: 4}}>Change logo</p>
              </label>
            <div className={styles.uploadAndRemoveAvatar}>
              {
                !avatarSrc?.includes('emptyEntreprise.jpg') && <p className={styles.removeAvatar} role="button" onClick={handleRemoveAvatar}>Remove</p>
              }
            </div>
          </div>
        ):
        (
          <label htmlFor="avatar-upload-input" className={styles.uploadAndRemoveAvatar}>
        <Input accept="image/*" id="avatar-upload-input" type="file" onChange={handleAvatarChange} />
        <p style={{cursor: 'pointer', paddingTop: 18}} className={styles.uploadAvatarBtn} role="button">
          Upload avatar
        </p>
        
      </label>
        ):
        <></>
      */}


    </div>
  );
};

export default AvatarUploadEntreprise;
