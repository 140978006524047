import styles from "../auth.module.scss"
import { useIntercom } from '../../main/intercom/IntercomSetup'

const authFooter = () => {
    const currentYear = new Date().getFullYear();

    const emailStorageVa = window.localStorage.getItem('email')
    const hmacValue = window.localStorage.getItem('hmac')
    const fullNamed = (window.localStorage.getItem("fullName") || "") + " " + (window.localStorage.getItem("name") || "");
    const profilePicture = window.localStorage.getItem("avatar")

    useIntercom('isows87y', {
        app_id: 'isows87y',
        api_base: 'https://api-iam.intercom.io',
        email: emailStorageVa as string,
        user_hash: hmacValue as string,
        name: fullNamed,
        avatar: profilePicture,
    })


    return (
        <div style={{ display: 'flex', gap: 16, justifyContent: 'center', alignItems: 'center' }}>
            <span className={styles.loginFooterText}>© {currentYear} Defendis Technology Inc.</span>
            <a href="https://defendis.ai/terms" className={styles.loginFooterText} target="_blank">
                <span>Terms</span>
            </a>
            <a href="https://defendis.ai/privacy" className={styles.loginFooterText} target="_blank">
                <span>Privacy Policy</span>
            </a>
            <a href="https://help.defendis.ai" className={styles.loginFooterText} target="_blank">
                <span>Support</span>
            </a>
        </div>
    );
}

export default authFooter;