import React from 'react'
import styles from '../modules.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendDown, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';

interface props {
    text: string;
    num: number;
    percent?:number;
}

function Card({ text, num, percent }: props) {
    return (
        <div style= {{padding: '16px 25px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className={styles.card}>
            <span style={{fontSize: 14, fontWeight: 500}}>{text}</span>
            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", marginTop: 0, paddingTop:32, paddingRight:0}}>
                <span style={{fontSize: 20, fontWeight: 600}}>{num.toLocaleString()}</span>
                {/* <p style={{fontSize: 12, color: percent > 0 ? "#20B444": 'red'}}>{percent} <FontAwesomeIcon icon={percent > 0 ? faArrowTrendUp : faArrowTrendDown} /></p>   */}
            </div>
        </div>
    )
}

export default Card
