import axios, { AxiosError } from "axios";
import { PageSort } from "../../../../../../core/api/threatIntelApi";
import { env } from "../../../../../../environement/env";
import { CredentialsLeak } from "../../../../../../core/constants";
import { useQuery } from "@tanstack/react-query";
type GetCredentialsLeakApiType = PageSort & {
    victimType: string,
    riskType: string,
    fromDate: string
}
export const getCredentialsLeakApi = async ({ riskType,page, fromDate, sortBy, search, victimType }: GetCredentialsLeakApiType) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/threatIntel/credentialsLeak?page=${page}&sortBy=${sortBy}&search=${search}&victimType=${victimType}&fromDate=${fromDate}&riskType=${riskType}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        });
        return data as CredentialsLeak;
    } catch (error) {
        if (error instanceof AxiosError) {
            throw new Error(error.response?.data?.message || 'Failed to fetch documents');
        }
        throw error;
    }
};
export function useGetDataCredentials({
    page, fromDate,
    sortBy,
    riskType,
    search, victimType
}: GetCredentialsLeakApiType) {
    return useQuery<CredentialsLeak, Error>({
        queryKey: ['credentialsLeak', { page, sortBy,riskType, search, victimType, fromDate }],
        queryFn: () => getCredentialsLeakApi({ page, sortBy,riskType, search, victimType, fromDate }),
        retry: 2,
        staleTime: 5 * 60 * 1000, // 5 minutes
    });
}
