import { faCalendar, faChevronDown, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga4';
import styles from '../../../modules.module.scss'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../../core/selectors/ransomeSelector'
import moment from 'moment'
import { CircularProgress } from '@mui/material'
import EmptyStateUrl from '../../../common/EmptyStateUrl'

type SortOrder = 'asc' | 'desc'

interface props {
  urls: any[] | null
}

function Urls({ urls }: props) {
  const { groupDetail, isLoadingGroups } = useSelector(ransomeSelector)
  const [sortBy, setSortBy] = useState<string>('lastscrape')
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc')

  useEffect(() => {
    document.title = 'Ransom Group Communities';
    ReactGA.send({ hitType: 'Ransom Group Communities', page: window.location.pathname });
  }, []);


  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(column)
      setSortOrder('asc')
    }
  }




  return (
    <>
      {
        !isLoadingGroups ?
          groupDetail?.slug?.length && urls?.length ? (
            <table className={styles.table} style={{ marginTop: 0 }}>
              <thead>
                <tr className={styles.tableHead}>
                  <th style={{ paddingLeft: 16, cursor: 'pointer' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <span>Links</span>
                    </div>
                  </th>
                  <th style={{ paddingLeft: 16, cursor: 'pointer' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <span>Active</span>
                    </div>
                  </th>
                  <th style={{ paddingLeft: 16, cursor: 'pointer' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <span>Latest Activity</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  urls && urls.map(item => <tr className={styles.tableActivityRowWatchList}>
                    <td style={{ cursor: 'pointer', padding: '16px', color: "#e9bd08", textDecoration: 'underline' }} className={styles.tableCell} onClick={() => window.open(item.slug, '_blank')}>
                      <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.slug?.substring(0, 100)}</span>
                    </td>
                    <td style={{ width: '60px', padding: '16px' }} className={styles.tableCell} >
                      {
                        item?.enabled ?
                          <FontAwesomeIcon icon={faCircleCheck} width={23} height={24} color="#20B444" />
                          :
                          <div style={{ marginLeft: 5, height: 8, width: 8, borderRadius: "100%", border: "3px solid #383838", backgroundColor: "transparent" }} />
                      }
                    </td>
                    <td style={{ width: '130px', padding: '16px' }} className={styles.tableCell} >
                      <div style={{ display: 'flex', alignItems: "center", gap: 5 }}>
                        <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey' />
                        <span>{moment(item.lastscrape).format("MMM DD, YYYY")}</span>
                      </div>
                    </td>
                  </tr>)
                }
              </tbody>
            </table>
          )
          :
          (
            <div className={styles.loaderVul}>
              <EmptyStateUrl />
            </div>
          ) : (
            <div className={styles.loaderVul}><CircularProgress /></div>
          )
          
      }
    </>
  )
}

export default Urls
