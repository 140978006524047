import React, { useEffect, useState } from 'react'
import styles from '../../../modules.module.scss'
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../../../core/selectors/threatIntelSelector'


function parseNameAtIndex(dataString: any, index: number) {
  try {
    // Parse the JSON string into an array
    const data = JSON.parse(dataString);

    // Access the specified index and extract the Name part
    if (index >= 0 && index < data.length) {
      const entry = data[index];
      // Assuming the format is always "Name: description"
      const namePart = entry.split(",")[0]; // Splits the entry and takes the first part
      return namePart.slice(namePart.indexOf(':') + 2); // Extracts the name value
    } else {
      return 'Index out of range'; // Handle out of range index
    }
  } catch (error) {
    console.error('Failed to parse data or extract name:', error);
    return null; // Return null or appropriate error handling
  }
}
export default function HardwareCredentials() {
  const { credentialsLeak } = useSelector(threatIntelSelector)
  const [credentialLeakDetail, setcredentialLeakDetail] = useState<any | null>()
  useEffect(() => {
    const data = window.localStorage.getItem("credentialLeakDetail")
    setcredentialLeakDetail(data ? JSON.parse(data) : null)
  }, [])

  // console.log(credentialLeakDetail?.user?.antivirus)
  const hardwareDetails = credentialLeakDetail?.user?.hardwares?.split("Name:")?.filter(Boolean)?.map((s: any) => s?.toLowerCase());

  const antiviruses = credentialsLeak?.antivirus?.filter((item: any) => Number(credentialLeakDetail?.userid) === Number(item?.userid))
  // console.log(credentialLeakDetail?.antivirus)
  return (
    <table className={styles.table} style={{ marginTop: 16 }}>
      <tbody>
        {credentialLeakDetail?.user?.hwid && (
          <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{ width: '180px', padding: '13px 16px' }} className={styles.tableCell}>
              Hardware ID
            </td>
            <td style={{ width: '918px', padding: '13px 16px' }} className={styles.tableCell}>
              {(credentialLeakDetail?.user?.hwid || "-").toUpperCase()}
            </td>
          </tr>
        )}

        {credentialLeakDetail?.user?.malware_path && (
          <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{ width: '180px', padding: '13px 16px' }} className={styles.tableCell}>
              Malware Path
            </td>
            <td style={{ width: '918px', padding: '13px 16px' }} className={styles.tableCell}>
              {credentialLeakDetail?.user?.malware_path || "-"}
            </td>
          </tr>
        )}

        {credentialLeakDetail?.user?.ipaddress && (
          <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{ width: '180px', padding: '13px 16px' }} className={styles.tableCell}>
              IP Address
            </td>
            <td style={{ width: '918px', padding: '13px 16px' }} className={styles.tableCell}>
              {credentialLeakDetail?.user?.ipaddress || "-"}
            </td>
          </tr>
        )}

        {credentialLeakDetail?.user?.username && (
          <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{ width: '180px', padding: '13px 16px' }} className={styles.tableCell}>
              Device Username
            </td>
            <td style={{ width: '918px', padding: '13px 16px' }} className={styles.tableCell}>
              {credentialLeakDetail?.user?.username || "-"}
            </td>
          </tr>
        )}

        {credentialLeakDetail?.user?.operatingsystem && (
          <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{ width: '180px', padding: '13px 16px' }} className={styles.tableCell}>
              Operating System
            </td>
            <td style={{ width: '918px', padding: '13px 16px' }} className={styles.tableCell}>
              {credentialLeakDetail?.user?.operatingsystem || "-"}
            </td>
          </tr>
        )}
        {/* <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '180px',padding: '13px 16px'}} className={styles.tableCell}>
          Process Elevation
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          Process Elevation
            </td>
        </tr> */}
        {
          antiviruses && antiviruses.length > 0 && (
            <tr className={styles.tableActivityRowRansomWatchList}>
              <td style={{ width: '180px', padding: '13px 16px' }} className={styles.tableCell}>
                Antivirus
              </td>
              <td style={{ width: '918px', padding: '13px 16px' }} className={styles.tableCell}>
                {antiviruses.map((item: any, index: number) => (
                  <span key={item?.id|| `antivirus-${index}`}>{item?.name + " " + item?.version}</span>
                ))}
              </td>
            </tr>
          )}
        {/* <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '180px', padding: '13px 16px' }} className={styles.tableCell}>
            Processor
          </td>
          <td style={{ width: '918px', padding: '13px 16px' }} className={styles.tableCell}>
            {
              credentialLeakDetail?.user?.hardwares?.split("Name:")?.filter(Boolean).map((s: any) => s.toLowerCase())?.find((s: any) => s.includes("core")) || credentialLeakDetail?.user?.hardwares?.split("Name:")?.filter(Boolean)?.[2] || '-'
            }
          </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '180px', padding: '13px 16px' }} className={styles.tableCell}>
            Graphics
          </td>
          <td style={{ width: '918px', padding: '13px 16px' }} className={styles.tableCell}>
            {
              (credentialLeakDetail?.user?.hardwares?.split("Name:")?.filter(Boolean)?.map((s: any) => s?.toLowerCase()), hardwareDetails?.find((s: any) => s?.includes("graphics") || s?.includes("nvidia")) || hardwareDetails?.[2] || '-')
            }
          </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{ width: '180px', padding: '13px 16px' }} className={styles.tableCell}>
            RAM
          </td>
          <td style={{ width: '918px', padding: '13px 16px' }} className={styles.tableCell}>
            {
              credentialLeakDetail?.user?.hardwares?.split("Name:")?.filter(Boolean).map((s: any) => s.toLowerCase())?.find((s: any) => s.includes("ram")) || credentialLeakDetail?.user?.hardwares?.split("Name:")?.filter(Boolean)?.[2] || '-'
            }
          </td>
        </tr> */}
        {credentialLeakDetail?.passwordid && (
          <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{ width: '180px', padding: '13px 16px' }} className={styles.tableCell}>
              Reference
            </td>
            <td style={{ width: '918px', padding: '13px 16px' }} className={styles.tableCell}>
              {credentialLeakDetail?.passwordid}
            </td>
          </tr>
        )}
        {/* {
          credentialLeakDetail?.user?.screenshot?.map((item: any, index: number) =>
            <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '180px',padding: '13px 16px'}} className={styles.tableCell}>
          Screenshot {index+1}  
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
            {item?.image_url}
            </td>
        </tr>
          )
        } */}


      </tbody>
    </table>
  )
}
